import React, { useState, useEffect } from "react";
import {
  Grid, Typography, Button, IconButton, InputAdornment,
  Backdrop, CircularProgress, TextField,   
} from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useStyles from "./styles"; 
// import { useUserDispatch } from "../../context/UserContext";
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { createUser } from '../../services/auth.api';
import CloseIcon from '@material-ui/icons/Close';
import { registerSchema, registerWithoutSchema } from "../../components/Yup/Yup";
//  import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
 import Lottie from "lottie-react";
 import animation from '../../images/chat.json';
 import logo from '../../images/infoapto.png';
 import PhoneInput from "react-phone-input-2";
 
 import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from 'react-simple-captcha';

import Notification from "../../components/Notification/Notification";
 
 import useGlobalStyles from "../../themes/style";
import "react-phone-input-2/lib/bootstrap.css";
 import "./index.css";
 
function Author275(props) { 
   var classes = useStyles();
  var globalClasses= useGlobalStyles(); 
 // var userDispatch = useUserDispatch();
  const [loader, setLoader] = useState(false);  
   const history = useHistory(); 
  // var [activeTabId, setActiveTabId] = useState(0); 
 
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }
  
  // const [valid, setValid] = React.useState(false);
   const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');

  const handleChange = (values, allDetails) => { 
 
    setCountryCode(allDetails.dialCode);
    setPhoneNumber(values);
  };

  useEffect(() => { 
    loadCaptchaEnginge(6);
  }, []);

  const onRegister = useFormik({
    initialValues: { 
      email: '',
      password: '', 
      firstName: '',
      lastName: '',   
      address:'',
      mobile:'',
      captcha:''
    },
     validationSchema: phoneNumber === "" ? registerSchema : registerWithoutSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => { 
      if (validateCaptcha(values.captcha) === true) {
      setLoader(true);
      createUser(values, phoneNumber, countryCode).then((response) => {    
        
      if (response.data.status === true) { 
         resetForm(); 
         setPhoneNumber("");
         setCountryCode("");
         handleNotificationCall("success", response.data.message); 
         history.push('/login');    
       } else { 
        handleNotificationCall("error", response.data.message); 
        loadCaptchaEnginge(6);
        onRegister.setValues({ 
          "email": onRegister.values.email,
          "password": onRegister.values.password,
          "firstName": onRegister.values.firstName,
          "lastName":onRegister.values.lastName, 
          "address":onRegister.values.address,
          "mobile": onRegister.values.mobile,
          "captcha":''
          
        })
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })
  } else {
    setSubmitting(false); 
    setLoader(false);
    loadCaptchaEnginge(6);
    handleNotificationCall("error", "Invalid Captcha");
    onRegister.setValues({ 
      "email": onRegister.values.email,
      "password": onRegister.values.password,
      "firstName": onRegister.values.firstName,
      "lastName":onRegister.values.lastName, 
      "address":onRegister.values.address,
      "mobile": onRegister.values.mobile,
      "captcha":''
    })
  }
    },
  }); 
 
 

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  

  return (
    <Grid container className={classes.container}>
     

      <div className={classes.logotypeContainer}>
        {/*  <img src={wave} alt="bg" className={classes.fullImg} />  */}
        <Lottie animationData={animation} loop={true}  className={classes.fullImg} />
        {/* <Typography className={classes.logotypeText}>  </Typography> */}
      </div>
     
      <div className={classes.formContainer}>
        <div className={classes.regForm}>

 
          <Typography  className={classes.loginText} ><img src={logo} alt="bg"   />  </Typography>
         
<React.Fragment>
                   <form onSubmit={onRegister.handleSubmit}  >

              <Grid container spacing={1}>
        <Grid item xs={6}>
      
        <TextField
                    id="firstName"
                   
                    name="firstName"
                    margin="normal"
                    placeholder="Enter First Name"
                    type="text"
                    classes={{ root: globalClasses.regCustomTextField }}
                    value={onRegister.values.firstName}
                    onChange={onRegister.handleChange}
                    
                    error={onRegister.touched.firstName && Boolean(onRegister.errors.firstName)}
                    helperText={onRegister.touched.firstName && onRegister.errors.firstName}
    
                  />

        </Grid>

        <Grid item xs={6}>
        <TextField
                    id="lastName"
                    
                    name="lastName"
                    margin="normal"
                    placeholder="Enter Last Name"
                    type="text"
                    classes={{ root: globalClasses.regCustomTextField }}
                    value={onRegister.values.lastName}
                    onChange={onRegister.handleChange}
                    
                    error={onRegister.touched.lastName && Boolean(onRegister.errors.lastName)}
                    helperText={onRegister.touched.lastName && onRegister.errors.lastName}
    
                  />
        </Grid>


            </Grid>

            <Grid container spacing={1}>
        <Grid item xs={6}>
      
            <TextField
                    id="email"
                    
                    name="email"
                    margin="normal"
                    placeholder="Enter Email"
                    type="email"
                    classes={{ root: globalClasses.regCustomTextField }}
                    value={onRegister.values.email}
                    onChange={onRegister.handleChange}
                    
                    error={onRegister.touched.email && Boolean(onRegister.errors.email)}
                    helperText={onRegister.touched.email && onRegister.errors.email}
    
                  />
        
        </Grid>

        <Grid item xs={6}>
      
                  <TextField
                    id="password"
                    InputProps={{ 
                      endAdornment: <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}   >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    }}
                    name="password"
                    margin="normal"
                    placeholder="Password"
                    type={values.showPassword ? 'text' : 'password'}
                    classes={{ root: globalClasses.regCustomTextField }}
    
                    value={onRegister.values.password}
                    onChange={onRegister.handleChange}
                    
                    error={onRegister.touched.password && Boolean(onRegister.errors.password)}
                    helperText={onRegister.touched.password && onRegister.errors.password}
                  />

</Grid>   </Grid>
 
<div  className="regCustomTextField"> 
<PhoneInput
      country={"in"}
      id="mobile"
      enableSearch={true}
      value={phoneNumber}
      classes={{ root: globalClasses.regCustomTextField }} 
      inputStyle={{boxShadow:'0px 10px 20px 0px #2b374412', borderRadius: '26px'}}
      
    onChange={(val, allDetails) => {
      handleChange(val, allDetails);
  }}
  
   margin="normal"
   name="mobile"
   variant="outlined"
  
    />
   <Typography variant="inherit" color="error">{ phoneNumber === ""?  onRegister.touched.mobile && onRegister.errors.mobile :""}</Typography>

    </div>
<TextField
                    id="address"
                    
                    name="address"
                    margin="normal"
                    placeholder="Enter address"
                    
                    classes={{ root: globalClasses.regCustomTextField }}
                    value={onRegister.values.address}
                    onChange={onRegister.handleChange}
                    
                    error={onRegister.touched.address && Boolean(onRegister.errors.address)}
                    helperText={onRegister.touched.address && onRegister.errors.address}
    
                  />
        <Grid container spacing={1}>
        <Grid item xs={6}>
      
        <div  style={{ textAlign: "left" }}>
                  <LoadCanvasTemplate />
                  </div>

   
</Grid>
<Grid item xs={6}>  <TextField
                    id="captcha"
                    
                    name="captcha"
                    margin="normal"
                    placeholder="Enter captcha"
                    
                    classes={{ root: globalClasses.regCustomTextField }}
                    value={onRegister.values.captcha}
                    onChange={onRegister.handleChange}
                    
                    error={onRegister.touched.captcha && Boolean(onRegister.errors.captcha)}
                    helperText={onRegister.touched.captcha && onRegister.errors.captcha}
    
                  /> </Grid>
</Grid>
              <div className={classes.creatingButtonContainer}>
                {loader ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                  type="submit"
                   
                    size="small"
                    variant="contained"
                    color="primary"
                    
                    className={classes.createAccountButton}
                    disabled={ onRegister.isSubmitting}
                  >
                    Create your account
                  </Button>
                )}
              </div>
              </form>
        
            </React.Fragment>
        </div>
        
      </div>

  <ToastContainer
  closeButton={
  <CloseButton className={classes.notificationCloseButton} />
  }
  closeOnClick={false}
  hideProgressBar={true}
  />


<Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Grid> 
  );
}

export default withRouter(Author275);
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
} 