import React, { useEffect, useRef, useState, useReducer } from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
 
 import { Button, InputBase, Paper, TextField,   InputLabel,  Backdrop, CircularProgress, Tooltip, Dialog, SwipeableDrawer, Chip  } from '@material-ui/core/';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
 import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import RefreshIcon from '@material-ui/icons/Refresh';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import SendIcon from '@material-ui/icons/Send';
import Popover from '@material-ui/core/Popover';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
 
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
  import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
  import DoneAllIcon from '@material-ui/icons/DoneAll';
import GetAppIcon from '@material-ui/icons/GetApp';
import PersonPinIcon from '@material-ui/icons/PersonPin';
 import SearchOutlined from '@material-ui/icons/SearchOutlined';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
 import Highlighter from "react-highlight-words";
 import red from '@material-ui/core/colors/red';
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import ImageIcon from '@material-ui/icons/Image';
import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@material-ui/lab"; 
import AddCircleIcon from "@material-ui/icons/AddCircle";

import HeadsetIcon from '@material-ui/icons/Headset';
import AttachFileIcon from '@material-ui/icons/AttachFile'; 
import {   FcGallery,  FcAudioFile, FcVideoFile, FcDocument } from "react-icons/fc";
import { FaUserTag } from "react-icons/fa";
// import CardContent from "@material-ui/core/CardContent";
// import CardActions from "@material-ui/core/CardActions";


import useStyles from "./styles";

 import { getAllMessages, markMessageRead, viewAllMessageContacts } from '../../services/chat.api'; 

 import { addTag,  viewAllTags} from '../../services/tags.api';  

 import { setContactTag, viewAllContactTags, removeContactTag } from '../../services/contact.api';  

 
import { sendTextMessage, sendAudioMessage, sendDocumentMessage, sendImageMessage, sendVideoMessage, } from '../../services/message.api';
import { isInitiated, uploadFile } from '../../services/instance.api';
import { tagSchema } from "../../components/Yup/Yup";

import { useFormik } from 'formik';

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function a11yProps(index) {

  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const getMuiTheme = () => createTheme({

  overrides: {
    MuiListItem:{
      root: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
      }
    },
    MuiBox: {
      root: {
        padding: '0px !important'
      }
    },
    MuiTab: {
      root: {
        maxWidth: '100% !important'
      }
    },
    MuiInputBase: {
      input: {
        padding: '10px !important',
        fontSize: "13px",
      },
      root: {
        width: '100%'
      }
    },
    MuiFormControl: {
      root: {
        width: '100% !important'
      }
    },
    MuiTypography: {
      subtitle1: {
        fontWeight: "500",
       fontFamily: '"Satoshi", serif !important',
      },
      body1: {
        color: "#121224",
       fontFamily: '"Satoshi", serif !important',
        fontSize: "14px",
      },
      body2: {
        color: "#121224",
       fontFamily: '"Satoshi", serif !important',
        fontSize: "13px",
      }
    },

    MuiPaper: {
      rounded: {
       // borderRadius: "30px"
      }
    },
    MuiDrawer: {
      paperAnchorBottom: {
        width: "50%",
        left: "30%"
      }
    },
    MuiOutlinedInput:{
      root:{
        border: "none !important", 
        borderColor: "unset !important",  
        height: "40px !important", 
        "& input": { 
          width: "100% !important", 
          height: "10px !important", 
      },
        
      },
    },
    MuiButton: { 

      root:{ 
          fontFamily: '"Lora", serif!important',
          fontSize: "14px",
          fontWeight: 500,
          boxShadow: "10px 20px 20px 5px #2f3c4a14 !important",
          "@media (max-width:959.95px)": {
            fontSize: "10px !important",
          }, 
      },
      label: {
       fontFamily: '"Satoshi", serif !important',
        fontSize: "14px",
        "@media (max-width:959.95px)": {
          fontSize: "10px !important",
        },
      },
      containedPrimary:{ 
      //  backgroundColor: "#064be2 !important",   
        backgroundColor: "#2196f3 !important", 
        textTransform:  "initial !important", 
        '&:active':{
        //  backgroundColor: "#064be2 !important",
          backgroundColor: "#2196f3 !important", 
          color:"#fff !important",
        },
        '&:hover':{
          //backgroundColor: "#064be2 !important",
          backgroundColor: "#2196f3 !important", 
          color:"#fff !important",
        },
        '&:disabled':{
         // backgroundColor: "#064be2c7 !important",
          backgroundColor: "#2196f3 !important", 
          color:"#fff !important",
        }
    },
    containedSizeSmall:{
      textTransform:  "initial !important", 
      padding:  "4px 10px !important",
      fontWeight: "300 !important",
    },
    containedSecondary:{ 
      backgroundColor: red[500] +"!important",
      '&:active':{
        backgroundColor: red[500] +"!important",
        color:"#fff !important",
      },
      '&:hover':{
        backgroundColor: red[500] +"!important",
        color:"#fff !important",
      }
    },
    },
  },
 
});
 
const Home = (props) => {

   
  var moment = require('moment-timezone');

  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }
 
  const [tagData, setTagData] = React.useState([]); 
  const [tagValue, setTagValue] = React.useState(null);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
 
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState({
    profile: false,
    search: false,
  });

  const [view, setView] = useState("File");
 
  const [tabOpen, setTabOpen] = useState(false); 

 
  const [text, setText] = useState("");
  const [messageText, setMessageText] = useState("");

  const [searchValue, setSearchValue] = useState("");

  const messageRef = useRef(null);
  const fileRef = useRef(null);

  const searchRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const hiddenImageInput = useRef(null);
  const hiddenVideoInput = useRef(null);
  const hiddenAudioInput = useRef(null);
 
  const [mobile, setMobile] = useState("");

 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 

  const handleImageChange = e => {
    setimageValue(e.target.files[0]); 
    setAudioValue(null);
    setVideoValue(null);
    setFileValue(null);
    setAnchorEl(null); 
    setState({ ...state, "bottom": true });
  };

  const handleFileChange = e => {
    setimageValue(null);
    setAudioValue(null);
    setVideoValue(null);
    setFileValue(e.target.files[0]);
    setAnchorEl(null);
    setState({ ...state, "bottom": true });
  };

  const handleAudioChange = e => {
    setimageValue(null);
    setAudioValue(e.target.files[0]);
    setVideoValue(null);
    setFileValue(null);
    setAnchorEl(null);
    setState({ ...state, "bottom": true });
  };

  const handleVideoChange = e => {
    setimageValue(null);
    setAudioValue(null);
    setVideoValue(e.target.files[0]);
    setFileValue(null);
    setAnchorEl(null);
    setState({ ...state, "bottom": true });
  };

 
  const [imageUrl, setImageUrl] = useState(undefined);
  const [imageOpen, setImageOpen] = useState(false);
  const [fileValue, setFileValue] = useState(null);
  const [imageValue, setimageValue] = useState(null);

  const [audioValue, setAudioValue] = useState(null);
  const [videoValue, setVideoValue] = useState(null);


  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const opens = Boolean(anchorEl);
  const ids = open ? 'simple-popover' : undefined;

  const handleImageClose = () => {
    setImageOpen(false);
    setImageUrl(undefined);
  };

  const handleImageOpen = (url) => {
    setImageOpen(true);
    setImageUrl(url);
  };


  const [state, setState] = useState({
    top: false,
    bottom: false,

  });
  const toggleDrawer = (anchor, open) => (event) => { 
    setState({ ...state, [anchor]: open });
  };


  const handleImageClick = () => {
    hiddenImageInput.current.click();
  };

  const handleDocumentClick = () => {
    hiddenFileInput.current.click();
  };

  const handleAudioClick = () => {
    hiddenAudioInput.current.click();
  };

  const handleVideoClick = () => {
    hiddenVideoInput.current.click();
  };



  const [profileList, setProfileList] = useState([]);


  const [foundUsers, setFoundUsers] = useState([]);
  const [messageUsers, setMessageUsers] = useState([]);

  
  const [conversations, setConversations] = useState([]);


  const [ valid, setValid] = useState(false);
  const token = localStorage.getItem('token');


  
  const onTagAdd = useFormik({
    initialValues: { 
      tag_name: '', 
    },
     validationSchema: tagSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      setLoader(true);
      addTag({tag_name: values.tag_name, token:token}).then((response) => {    
      if (response.data.status === true) { 
        handleNotificationCall("success", response.data.message);  
        forceUpdate(); 
        resetForm();
          setOpen({
            ...open, tag:false})
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    })

    },
  });  


  useEffect(() => {

   // setLoader(true);
    
   
   viewAllMessageContacts({token:token}).then((response) => {    
    
        if(response.data.status === true) {  
          //setLoader(false);
          setProfileList(response.data.data);
          if(searchValue === ""){
            setFoundUsers(response.data.data); 
          } 
          
        } 
         
       }) 
       .catch(function (error) {
        console.log(error);
      })

   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerValue])

  
  useEffect(() => {

    // setLoader(true); 
    viewAllTags({token:token}).then((response) => {    
     
         if(response.data.status === true) {  
           //setLoader(false);
           setTagData(response.data.data);
           
           
         } 
          
        }) 
        .catch(function (error) {
         console.log(error);
       })
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue])
 
  
function handleTag(value, id){
   
  setLoader(true); 
  setContactTag({tag_id:value.id, message_contact_id: id, token:token}).then((response) => {    
     
    if(response) {  
     setLoader(false);
     setTagValue(null);
      handleTagClose(); 
      getAllContactTags(mobile);
    } 
     
   }) 
   .catch(function (error) {
    console.log(error);
  })

// eslint-disable-next-line react-hooks/exhaustive-deps
}
 
  
function getAllContactTags(value){
   
  setLoader(true); 
  viewAllContactTags({phoneNumber: value, token:token}).then((response) => {    
    
    if(response.data.status === true) {       
      setChipData(response?.data?.data); 
     } 
     setLoader(false); 
   }) 
   .catch(function (error) {
    console.log(error);
  })

// eslint-disable-next-line react-hooks/exhaustive-deps
}

  // function getChatUsers() {
  //   setLoader(true);

  //   axios({
  //     method: 'post',
  //     url: `${process.env.REACT_APP_SERVER}chat/getChatUsers`,
  //     data: {},
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': token
  //     },

  //   })
  //     .then(function (response) {

  //       if (response.data.status === true) {
  //         setLoader(false);

  //         setProfileList(response.data.chatdata)
  //         setFoundUsers(response.data.chatdata);

  //       }

  //     })
  // }



  function scroll() {

    const timeout = setTimeout(() => {
      const element = document.getElementById('section' + localStorage.getItem("mobile"));

    element.scrollIntoView({ behavior: 'smooth' });
    }, 500)

    return () => clearTimeout(timeout)


  }


  
  const [intervalId, setIntervalId] = useState(null);
  const stopInterval = () => {
    if (intervalId !== null) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  };

  function getmessage(mobile) { 
    stopInterval();
//setLoader(true);
    
const id = setInterval(() => {
       
      getAllMessages({phone_number: mobile,token:token}).then((response) => {    
        setMobile(mobile);
        localStorage.setItem("mobile", mobile)

        if (response.data.status === true) {
           //getmessageIni(mobile);
          setView("Message");
          setConversations(response.data.data);
         scroll();
        } else {
          setConversations([]);
        }
        setLoader(false); 
      }) 
      .catch(function (error) {
       console.log(error);
     })    

     markMessageRead({mobile_number: mobile, token:token }).then((response) => {    
       
      if (response) {
        
        forceUpdate();
      }   
    }) 
    .catch(function (error) {
     console.log(error);
   })  

        if(valid!==true){
          isInitiated({mobile,  token:token}).then((response) => {    
              
            if (response) {
              setValid(response.data.result); 
            }  
          }) 
          .catch(function (error) {
          console.log(error);
        })  
        }
 
 
    }, 60000);

    getAllMessages({phone_number: mobile, token:token}).then((response) => {    
      setMobile(mobile);
      localStorage.setItem("mobile", mobile)

      if (response.data.status === true) {
         //getmessageIni(mobile);
        setView("Message");
        setConversations(response.data.data);
       scroll();
      } else {
        setConversations([]);
      }
      setLoader(false); 
    }) 
    .catch(function (error) {
     console.log(error);
   })    
   
    setIntervalId(id);
    setMessageText("");
    setMessageUsers([]);

     isInitiated({mobile,  token:token}).then((response) => {    
       
      if (response) {
         setValid(response.data.result); 
      }
    }) 
    .catch(function (error) {
     console.log(error);
   })  

   markMessageRead({mobile_number: mobile, token:token }).then((response) => {    
       
    if (response) {
      
      forceUpdate();
    }   
  }) 
  .catch(function (error) {
   console.log(error);
 })  


    
 viewAllContactTags({phoneNumber: mobile, token:token}).then((response) => {    
    
  if(response.data.status === true) {       
    setChipData(response.data.data);
   } 
   
 }) 
 .catch(function (error) {
  console.log(error);
})

  }

  

  function sendFile(mobile, Id) {

   
    // var FormData = require('form-data');
    // var data = new FormData();
    // data.append('chatUserId', Id);
    // data.append('file', imageValue !== null ? imageValue : fileValue);

    // var url = "";

    // if (imageValue !== null) {
    //   url = `${process.env.REACT_APP_SERVER}chat/sendImage`;
    // } else {
    //   url = `${process.env.REACT_APP_SERVER}chat/sendDocument`;
    // }

    // var config = {
    //   method: 'post',
    //   url: url,
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     'Authorization': token
    //   },
    //   data: data
    // };

    // axios(config)
    //   .then(function (result) {
    //     setLoader(false);

    //     scrollDelay(Id);
    //     getmessage(Id);
    //     setState({ ...state, "bottom": false });

    //   })

    //   .catch(function (error) {
    //     console.log(error);
    //   });
 
 
    var FormData = require("form-data");
    var data = new FormData();
    if(imageValue !== null && imageValue.size < 5000000){
      setLoader(true);  
     
      data.append("file", imageValue);  
      uploadFile(data, { token: token}).then((response) => {    
    
        if (response.data.status === true) {  
         sendImageMessage({  mobile_number:mobile,   file_url: response.data?.file_url,
        file_path: response.data?.file_path, instance_id: Id, token:token}).then((result) => {    
        if (result.data.status === true) { 
          handleNotificationCall("success", result.data.message);  
          setimageValue(null);
        
         scrollDelay(mobile);
        getmessage(mobile);
        setState({ ...state, "bottom": false });
         } else {  
          handleNotificationCall("error", result.data.message);  
         }
         setLoader(false);

       })  
         
         } else {  
          setLoader(false);
          handleNotificationCall("error", response.data.message);  
         }
        
       }) 
    } else if(audioValue !== null && audioValue.size < 16000000){
      setLoader(true); 
    
      
      data.append("file", audioValue);
      data.append("token", token);
      uploadFile(data, { token: token}).then((response) => {    
      
        if (response.data.status === true) {  
          sendAudioMessage({ mobile_number:mobile, file_url: response.data?.file_url,
            file_path: response.data?.file_path, instance_id: Id, token:token}).then((result) => {    
             
          if (result.data.status === true) { 
            handleNotificationCall("success", result.data.message);  
           
            setAudioValue(null);
            scrollDelay(mobile);
            getmessage(mobile);
            setState({ ...state, "bottom": false });
           
           } else {  
            handleNotificationCall("error", result.data.message);  
           }
         
           setLoader(false);

         }) 
         .catch(function (error) {
          console.log(error);
        })
         
        } 
       
       
    }) 
    } else if(videoValue !== null  && videoValue.size < 16000000){
      setLoader(true); 
      
       
      data.append("file", videoValue);
       uploadFile(data, { token: token}).then((response) => {    
      
        if (response.data.status === true) {  
          sendVideoMessage({ mobile_number:mobile, file_url: response.data?.file_url,
            file_path: response.data?.file_path, instance_id: Id, token:token}).then((result) => {    
          if (result.data.status === true) { 
            handleNotificationCall("success", result.data.message);  
            setVideoValue(null);
            scrollDelay(mobile);
            getmessage(mobile);
            setState({ ...state, "bottom": false });
           
           } else {  
            handleNotificationCall("error", result.data.message);  
           }
           
           setLoader(false);
         }) 
         .catch(function (error) {
          console.log(error);
        })
     
        } 
        
       
    }) 
    }else if(fileValue !== null  && fileValue.size < 100000000){
      setLoader(true); 
     
      
      data.append("file", fileValue);
       uploadFile(data, { token: token}).then((response) => {    
      
        if (response.data.status === true) {  
    
          sendDocumentMessage({ mobile_number:mobile, file_url: response.data?.file_url,
            file_path: response.data?.file_path, instance_id: Id, token:token}).then((result) => {    
          if (result.data.status === true) { 
            handleNotificationCall("success", result.data.message);  
            setFileValue(null);
            scrollDelay(mobile);
            getmessage(mobile);
            setState({ ...state, "bottom": false });
           
           } else {  
            handleNotificationCall("error", result.data.message);  
           }
            
           setLoader(false);
         }) 
         .catch(function (error) {
          console.log(error);
        })
        } 
       
        
    }) 
    } else{
    
      handleNotificationCall("error", "Please upload a smaller file size");  
    } 
   


  }




  // function getmessageIni(mobile) {

  //   axios({
  //     method: 'post',
  //     url: `${process.env.REACT_APP_SERVER}chat/getIniChat`,
  //     data: {
  //       "chatUserId": mobile
  //     },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': token
  //     },

  //   })
  //     .then(function (response) {

  //       if (response.data.status === true) {
  //         if (response.data.data === null) {
  //           setView("undefined");
  //         } else {
  //           setView("Message");
  //         }

  //       }

  //     })

  // }





  // function chatmessage(data) {

  //   if (data.from === localStorage.getItem("mobile")) {
  //     getmessage(localStorage.getItem("mobile"));
  //    // getChatUsers();
 
  //   }

  // }


//   useEffect(() => {

//     const socket = io.connect(`${process.env.REACT_APP_URL}`);

//     socket.on('connnection', () => {
//       //console.log('connected to server');
//     })


//     socket.on('message-recevied', (newOrders) => {

//       chatmessage(newOrders);

//     }) 
//     socket.on("disconnect", () => {
//      // console.log("User Disconnected", socket.id);
//     });

//  // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])

  const filter = (e) => {
     const keyword = e.target.value;
 
    setSearchValue(e.target.value); 

    if (keyword !== '') { 

      const results = foundUsers.filter((user) => {
        return (user?.name !== null && user?.name.toLowerCase().includes(keyword.toLowerCase())) ||
               (user?.from_number !== null && user?.from_number.includes(keyword.toLowerCase())) ||
               (user?.to_number !== null && user?.to_number.includes(keyword.toLowerCase())) ||
               (user?.caption !== null && user?.caption.toLowerCase().includes(keyword.toLowerCase())) || 
               (user?.phno !== null && user?.phno.includes(keyword.toLowerCase()));
      });

       setFoundUsers(results);


      // setLoader(true);
      // axios({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_SERVER}chat/searchChat`,
      //   data: { "search": searchValue },
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': token
      //   },

      // })
      //   .then(function (response) {

      //     if (response.data.status === true) {


      //       setFoundUsers(response.data.data);

      //     } else {

      //       setConversations([]);
      //     }
      //     setLoader(false);

      //   })



    } else {
      setFoundUsers(profileList);
    }
  }


  const search = (e) => {
    const keyword = e.target.value;
    setMessageText(e.target.value);

    if (keyword !== '') {
      setLoader(true);

      const results = conversations.filter((msg) => {
        return msg.caption?.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;

      });

      setLoader(false);

    

      setMessageUsers(results);
    } else {
      setMessageUsers([]);

    }
  }




  function scrollDelay(mobile) {

    const timeout = setTimeout(() => {
      const element = document.getElementById('section' + mobile);

      element.scrollIntoView({ behavior: 'smooth' });
    }, 1000)

    return () => clearTimeout(timeout)


  }


  const handleClick = () => {
    //history.push("/app/instance");
    props.setValue(2); 
  }

  const [chipData, setChipData] = useState([]);
 

  const handleDelete = (chipToDelete) => () => {
 

   // setChipData((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));

  
   setLoader(true);
   removeContactTag({id: chipToDelete.contactTags_id, token:token}).then((response) => {    
   if (response.data.status === true) { 
     handleNotificationCall("success", response.data.message);  
     getAllContactTags(mobile);

     
    } else {  
     handleNotificationCall("error", response.data.message);  
    }
    
    setLoader(false);
  }) 
  .catch(function (error) {
   console.log(error);
 })

    
  };




  function sendMessage(mobile, instanceId) {

    
 
      const form = messageRef.current

      //setLoader(true);
      sendTextMessage({
        "mobile_number": mobile,
        "message": `${form['text'].value}`
      },  {instance_id: instanceId, token:token} ).then((response) => {    
      if (response.data.status === true) { 
        
         scrollDelay(mobile);
        getmessage(mobile);
        form.reset();

       } else {  
        handleNotificationCall("error", response.data.message);  
       }
     
       
     }) 
     .catch(function (error) {
      console.log(error);
    })



  }

 



  function TabList(props) {
    const { value, index } = props;
  
    return (<>
  

      <Tab  style={{ width:"100%"}} label={
        <>
       
          <Grid className={classes.chatList}> 

          
         
            <Avatar alt="Image" className={classes.chatListAvatar} />

            <Grid container direction="row" spacing={2} >
              <div  className={classes.space}  > 
              <Typography className={classes.userName} > {value.name? value.name: value.phno}  </Typography> 
              <span className={classes.time} >   <Typography variant="body2" className={classes.text} >{moment(moment(value.created).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm a')} </Typography>      </span>
             </div>
             <div  className={classes.space}  > 

                {value.caption !== "" ? <>  <Typography variant="body2" className={classes.userCaption}     > { value.caption.substring(0, 10)}... </Typography></>
                :  value.format === "image" ? <> <Typography variant="body2" className={classes.userCaption}     > <ImageIcon    /> Photo</Typography></>
                :  value.format === "audio" ? <> <Typography variant="body2" className={classes.userCaption}     > <HeadsetIcon    /> Audio</Typography></>
                :  value.format === "video" ? <> <Typography variant="body2" className={classes.userCaption}     > <VideocamIcon    /> Video</Typography></>
                :  value.format === "document" ? <> <Typography variant="body2" className={classes.userCaption}     > <DescriptionIcon    /> Document</Typography></>
                :  value.format === "contact" ? <> <Typography variant="body2" className={classes.userCaption}     > <PersonIcon    /> Contact</Typography></>
                :  value.format === "location" ? <> <Typography variant="body2" className={classes.userCaption}     > <RoomIcon    /> Location</Typography></>
                
                : ""}
              
              <span className={classes.time} >   {value?.from_number ?  value?.status !== "marked_read" ? <> 
                <Typography variant="body2" className={classes.chatListMessage}>1</Typography>  </> : "" : ""}
              </span>

            </div>
          </Grid>
          </Grid>
        </>}

onClick={(e) => { 
  getmessage(value.phno); 
  setTabOpen(true);
  setOpen({
    ...open, search:false, profile:false})
   
 }}

        {...a11yProps(index)} key={index}
      
 
      />


    </>
    );
  }
 


//   const wsUri = 'ws://research.ramsol.in/';
// const websocket = new WebSocket(wsUri);

//   useEffect(() => {
    
//     websocket.onopen = () => {
 //     };

 

//     websocket.onmessage = (data) => {
 //     };

 

//     return () => {
//       websocket.disconnect();
//     };
    
//   }, []);

const [anchorTagEl, setAnchorTagEl] = React.useState(null);

const handleTagClick = (event) => {
 
  setAnchorTagEl(event.currentTarget);
};

const handleTagClose = () => {
  setAnchorTagEl(null);
};

const openTag = Boolean(anchorTagEl);
const idTag = openTag ? 'simple-popover' : undefined;


const list = (anchor, Id) => (
   
  <Box sx={{ width: '100%' }} role="presentation"  >
  
        <div className={classes.drawerHeader}>
          <CloseIcon size="14px" onClick={toggleDrawer(anchor, false)} />
  
        </div>
  
  
        <List className={classes.messagearea}    >
  
          <div className={classes.messageRight} >
            <PersonPinIcon className={classes.messageRightAvatar} />
  
  
            <div className={ imageValue !== null ? classes.messageRightImage : classes.messageRightFile }  >
  
              {imageValue !== null ?
                <img src={URL.createObjectURL(imageValue)} className={classes.fullWidth} alt="chat_image" />
  
  
                : fileValue !== null? 
  
  
                <List >
  
                  <ListItem>
                    <ListItemAvatar>
  
                      <FcDocument style={{ fontSize: "30px"}} />
  
                    </ListItemAvatar>
                    <div className={classes.lgButton}> 
                    <ListItemText
                      primary={fileValue?.name}   />
                     </div>
                    {/* <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <GetAppIcon />
                      </IconButton>
                    </ListItemSecondaryAction> */}
                  </ListItem>
  
                </List>

                : videoValue !== null? 
                <List >
  
                <ListItem>
                  <ListItemAvatar>

                    <FcVideoFile style={{ fontSize: "30px"}} />

                  </ListItemAvatar>
                  <div className={classes.lgButton}> 
                  <ListItemText
                    primary={videoValue?.name}   />
                   </div>
                  {/* <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <GetAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction> */}
                </ListItem>

              </List>
                : audioValue !== null? 
                <List >
  
                <ListItem>
                  <ListItemAvatar>

                    <FcAudioFile style={{ fontSize: "30px"}} />

                  </ListItemAvatar>
                  <div className={classes.lgButton}> 
                  <ListItemText
                    primary={audioValue?.name}   />
                   </div>
                  {/* <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                      <GetAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction> */}
                </ListItem>

              </List>

                : ""
              }
            </div>
  
  
  
          </div>
  
        </List>
  
  
        <form ref={fileRef} onSubmit={(e) => {
          e.preventDefault();
          sendFile(mobile, Id)
        }} className={classes.chatListBackGround} >
  
  
          <Paper className={classes.paper} >
  
  
          </Paper>
   
  
          <Button variant="contained"  type='submit' size="small"   
          className={classes.sendIconBtn} > Send</Button>
  
        </form>
  
        </Box>
    );
  

    
  return (
    <MuiThemeProvider theme={getMuiTheme()}>

      <div className={classes.mainContent}>
        <div className={classes.main} >
      
          <Grid container className={classes.fullWidth}>
          <Grid item xs={12} sm={5}  md={4} lg={3} >
          { tabOpen === false?
<> 
              
         
                <div className={classes.smButton}> 

                <Divider />
                <Grid item xs={12} className={classes.paperContainer}>
                  <Paper sx={{ p: '2px 4px' }} className={classes.paper}>

                    <FormControl mt={4}>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        inputProps={{ 'aria-label': 'search' }}

                        onChange={(e) => { 
                         
                          filter(e);
                        }}
                        variant="filled"
                      />

                    </FormControl>
                
                    {/* <IconButton aria-label="search" className={classes.searchOutLine} onClick={(e) => filter()}>
                      <SearchOutlined />
                    </IconButton> */}

                  </Paper>


                </Grid>
                <Divider />

                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical"
                  className={classes.leftSide}
                  TabIndicatorProps={{ style: { background: "none" } }}
                >


                  {foundUsers.map((row, index, arr) => {

                    return (
                      <div key={index}>
                        <TabList value={row} index={index }  /> 
                        <Divider />
                      </div>
                    )
                  })
                  }
                </Tabs>
                
</div>
             
</>
            : ""}

<div className={classes.lgButton}> 
                <Divider />
                <Grid item xs={12} className={classes.paperContainer}>
                  <Paper sx={{ p: '2px 4px' }} className={classes.paper}>

                    <FormControl mt={4}>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        inputProps={{ 'aria-label': 'search' }}

                        onChange={(e) => { 
                          filter(e);  
                           }}
                        variant="filled"
                      />

                    </FormControl>

                    {/* <IconButton aria-label="menu" className={classes.searchOutLine} onClick={(e) => filter()}>
                      <SearchOutlined />
                    </IconButton> */}

                  </Paper>


                </Grid>
                <Divider />

                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical"
                  className={classes.leftSide}
                  TabIndicatorProps={{ style: { background: "none" } }}
                >


                  {foundUsers.map((row, index, arr) => {

                    return (
                      <span key={index}>
                        <TabList value={row} index={index}   />

                        <Divider />
                      </span>
                    )
                  })
                  }
                </Tabs>
                </div>
            </Grid> 
          
            { tabOpen === true?
            <> 
            <Grid item xs={12} sm={7}   md={8} lg={9}  className={classes.contentShift}>   
 
 
              {foundUsers.filter(item => item.phno === mobile).map((row, index, arr) => {
 
                  return (
                  <>
                    <TabPanel value={value} index={index} >  

                      <List className={classes.list}>
                        <ListItem key={index + 1}   >
                        <Grid   className={classes.listGridItem}> 
                        <ArrowBackIosIcon  className={[classes.blueIcon, classes.a]} onClick={(e)=>{  setTabOpen(false);  }} />
                         
                           <ListItemIcon className={classes.listItemIcon}  onClick={() =>  setOpen({ ...open, profile:true})} >
                            <Avatar alt="Image" src="" sx={{ bgcolor: "white" }} /><Typography className={classes.avatarTitle}>{  row.name? row.name: row.phno}  </Typography>
                          </ListItemIcon>
                         <div className={classes.chip} >
                          {chipData.map((data) => {
        let icon;
 
         return (
          <li key={data.key}>
            <Chip
              icon={icon}
              label={data.name}
              onDelete={ handleDelete(data)}
              
            />
          </li>
        );
      })}
 </div>

</Grid>



<Grid className={classes.listItem} >

<Tooltip title="Add to Tag"
placement="bottom"
aria-label="tag"> 

<IconButton aria-label="menu" aria-describedby={idTag} className={classes.searchOutLine} onClick={handleTagClick}>
    <FaUserTag />
  </IconButton> 
   
</Tooltip>  

<Tooltip title="Add New Tag"
placement="bottom"
aria-label="tag"> 

<IconButton aria-label="menu" onClick={() =>  setOpen({
    ...open, tag:true})} >
    <AddCircleIcon />
  </IconButton> 
   
</Tooltip>  

          <Popover id={idTag}  open={openTag} anchorEl={anchorTagEl} onClose={handleTagClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          > 
       
       <div style={{ display: "flex", width: "100%"}}> 
       
        <Autocomplete  underlineShow={false}  options={tagData} 
        getOptionLabel={(option) =>
          option.name !==undefined? `${ option.name }` : ''
        }
          value={ tagValue }
          onChange={(e, value) => {  
          setTagValue(value);  
           handleTag(value, row?.message_contact_id);
          }
        } 
        renderInput={(params) => (
          <TextField 
            {...params}
            name="instance" 
            fullWidth 
            variant="outlined"
            InputLabelProps={{ shrink: true }} 
            type="text"
            style={{width: "160px"}}
            className={classes.auto}
          />
        )}
      /> 
 
                    </div>
          </Popover>



<Tooltip title="Search"
placement="bottom"
aria-label="search"> 
  <IconButton aria-label="menu" className={classes.searchOutLine} onClick={() => { setOpen({
    ...open, search:true}); setMessageUsers([])} }>
    <SearchOutlined />
  </IconButton>
</Tooltip>
<Tooltip title="Refresh"
placement="bottom"
aria-label="refresh"> 
  <IconButton aria-label="refresh" className={classes.searchOutLine} onClick={(e) => getmessage(mobile) }>
   <RefreshIcon />
  </IconButton>
  </Tooltip>   
</Grid>
                        </ListItem>

                        
                    
                      </List>


                      <List className={classes.messagearea}    >

                        {conversations.map((row, index, arr) => {
  
 
 
                          return (
                            <>
                              {row.from_number !== "" ? <>

                                <div className={classes.message}> 
                                  <PersonPinIcon className={classes.messageAvatar} />  
                                  <div className={row.caption?.length < 100 ? classes.messagebubble : classes.messagebubbles } > 

                                    {row.type === "text" && row.format !== "contact" ?


                                                            row.replyMessageId?
                                                            <>  
                                                            <div className={classes.replyMessage}>  
                                                            {/* {conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption !==""?
                                                            conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption
                                                          :
                                                          <img src={`${process.env.REACT_APP_URL}`+conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.url} className={classes.fullWidth} alt="chat_image" />
                                                          }  */}

                {conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.type === "text" && 
                conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format !== "contact" ?
                <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[messageText]}
                autoEscape={true}
                textToHighlight={row.caption}
              />
                  : conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.type === "file" && 
                  conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format === "image" ?
                  <img src={`${process.env.REACT_APP_URL}`+conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.url} className={classes.fullWidth} alt="chat_image" />

 
                  
                  : conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.type === "file" && 
                  conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format === "audio" ?
                  <audio src={`${process.env.REACT_APP_URL}`+conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.url} controls className={classes.fullWidth} />

                  : conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.type === "file" && 
                  conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format === "document" ?
                  <a href={`${process.env.REACT_APP_URL}`+conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.url} target="_blank" rel="noreferrer" download>
                  <iframe src={`https://docs.google.com/a/umd.edu/viewer?url=${process.env.REACT_APP_URL+row.url}&embedded=true`} title="File" width="100%" height="200" > </iframe>
                  <br />

                  <List >

                    <ListItem>
                      <ListItemAvatar style={{minWidth: "40px"}}>

                        <DescriptionIcon className={classes.descriptionIcon} />

                      </ListItemAvatar>
                      <div className={classes.lgButton}> 
                      <ListItemText
                      primary={`${process.env.REACT_APP_URL}`+conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.url.replace("assets/Documents/", '')}

                      />
                      </div>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete">
                          <GetAppIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>

                  </List></a>

                  : conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.type === "file" && 
                  conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format === "video" ?

                  <video controls width={"100%"}>
                  <source src={`${process.env.REACT_APP_URL}`+conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.url} type="video/mp4" />

                </video>  
               :  conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format === "contact" ?

<List >

<ListItem style={{ padding: "0px"}}>
  <ListItemAvatar>

  <Avatar alt="Image"    />
  </ListItemAvatar>
   <ListItemText
    primary={conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.contactName} 
    secondary={conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.contactNumber} 
  />
  
</ListItem>

</List>

               : conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.type === "file" && 
                  conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format === "sticker" ?
                
                  <img src={`${process.env.REACT_APP_URL}`+conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.url} className={classes.fullWidth} alt="chat_image" />

                  : conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.format === "location" ?


                  <List >
                                                   
                  <ListItem>
                    <ListItemAvatar style={{minWidth: "40px"}}>
                 
                      <RoomIcon  className={classes.descriptionIcon} />
                 
                    </ListItemAvatar>
                    <div className={classes.lgButton}> 
                    <ListItemText
                      primary={<> {"Address: " + conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.locationAddress}  <br/> {"Latitude: " + conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.latitude} <br/>  {"Longitude: " +conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.longitude} </>  }
                 
                    />
                    </div>
                   
                  </ListItem>
                 
                 </List>
:  <Highlighter
highlightClassName="YourHighlightClass"
searchWords={[messageText]}
autoEscape={true}
textToHighlight={row.caption}
/>  }





                                                            </div>
                                                            <br/>
                                                              <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[messageText]}
                                                                autoEscape={true}
                                                                textToHighlight={row.caption}
                                                              />
                                                              </>
                                                            : 

                                                            <> 

                                                            {row.format === "template_header" && row.uri !==""?
                                                            <img src={row.uri} className={classes.fullWidth} alt="chat_image" />
                                                            :""}
                                                            <Highlighter
                                                            highlightClassName="YourHighlightClass"
                                                            searchWords={[messageText]}
                                                            autoEscape={true}
                                                            textToHighlight={row.caption}
                                                            />
                                                              </>
                                                          

                                      : row.type === "file" &&  row.format === "image" ?

                                        <>
                                          <img src={`${process.env.REACT_APP_URL}`+row.url} className={classes.fullWidth}
                                            onClick={() => handleImageOpen(row.url)} alt="chat_image" />  <br/>
                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[messageText]}
                                    autoEscape={true}
                                    textToHighlight={row.caption}
                                    />
                                        </>
                                        : row.type === "file" &&  row.format === "audio" ?

                                          <audio src={`${process.env.REACT_APP_URL}`+row.url} controls className={classes.fullWidth} />

                                          : row.type === "file" &&  row.format === "document" ?
                                            <>



                                              <a href={`${process.env.REACT_APP_URL}`+row.url} target="_blank" rel="noreferrer" download>
                                                <iframe src={`https://docs.google.com/a/umd.edu/viewer?url=${process.env.REACT_APP_URL+row.url}&embedded=true`} title="File" width="100%" height="200" > </iframe>
                                                <br />

                                                <List >

                                                  <ListItem>
                                                    <ListItemAvatar style={{minWidth: "40px"}}>

                                                      <DescriptionIcon className={classes.descriptionIcon} />

                                                    </ListItemAvatar>
                                                    <div className={classes.lgButton}> 
                                                    <ListItemText
                                                    primary={row?.url.replace("assets/Documents/", '')}

                                                    />
                                                    </div>
                                                    <ListItemSecondaryAction>
                                                      <IconButton edge="end" aria-label="delete">
                                                        <GetAppIcon />
                                                      </IconButton>
                                                    </ListItemSecondaryAction>
                                                  </ListItem>

                                                </List></a>
                                            </>
                                            :  row.format === "contact"  ?
                                              <>


<List >

<ListItem style={{ padding: "0px"}}>
  <ListItemAvatar>

  <Avatar alt="Image"    />
  </ListItemAvatar>
   <ListItemText
    primary={row.contactName} 
    secondary={row.contactNumber} 
  />
  
</ListItem>

</List>

                                                


                                              </> : row.type === "file" &&  row.format === "video" ?
                                                <>
                                                  <video controls width={"100%"}>
                                                    <source src={`${process.env.REACT_APP_URL}`+row.url} type="video/mp4" />

                                                  </video>  <br/>

                                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[messageText]}
                                    autoEscape={true}
                                    textToHighlight={row.caption}
                                    />
                                                </> : row.type === "file" &&  row.format === "sticker" ?

                                                  <>
                                                    <img src={`${process.env.REACT_APP_URL}`+row.url} className={classes.fullWidth} alt="chat_image" />

                                                  </>
                                                    : row.type === "location"?

                                                    <List >
                                                   
                                                    <ListItem>
                                                      <ListItemAvatar style={{minWidth: "40px"}}>
                                                   
                                                        <RoomIcon  className={classes.descriptionIcon} />
                                                   
                                                      </ListItemAvatar>
                                                      <div className={classes.lgButton}> 
                                                      <ListItemText
                                                        primary={<> {"Address: " + row.locationAddress}  <br/> {"Latitude: " + row.latitude} <br/>  {"Longitude: " +row.longitude} </>  }
                                                   
                                                      />
                                                      </div>
                                                     
                                                    </ListItem>
                                                   
                                                   </List>

                                                  :      row.replyMessageId?<> 
                                                <div className={classes.replyMessage}> 
                                                {conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption !==""?
                                    conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption
                                   :
                                   <img src={conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.uri} className={classes.fullWidth} alt="chat_image" />
                                   } 
                                                  </div>
                                                  <br/>
                                                    <Highlighter
                                                      highlightClassName="YourHighlightClass"
                                                      searchWords={[messageText]}
                                                      autoEscape={true}
                                                      textToHighlight={row.caption}
                                                    />
                                                    </>
                                                  : 
                                                  <> 

                                               {row.format === "template_header" && row.uri !==""?
                                                  <img src={row.uri} className={classes.fullWidth} alt="chat_image" />
                                                  :""}
                                                  <Highlighter
                                                  highlightClassName="YourHighlightClass"
                                                  searchWords={[messageText]}
                                                  autoEscape={true}
                                                  textToHighlight={row.caption}
                                                  />
                                                    </>

}

                                    <Grid container direction="row" spacing={2} className={[classes.space, classes.paperContainer]}>
                                      <div  className={classes.bubbleSpan}  >   {moment(moment(row.created).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm a')}</div>
 
                                    </Grid>
                                  </div>
                                </div>
                              </>
                                : row.to_number !== "" ? <>
                                  <div className={classes.messageRight} >
                                    <PersonPinIcon className={classes.messageRightAvatar} />


                                    <div className={row.caption?.length < 100 ? classes.messageRightBubble : classes.messageRightBubbles} >

                                      {  row.type === "text" && row.format !== "contact" ?
                                      
                                      
                                      row.replyMessageId?<> 
                                      <div className={classes.replyMessage}> 
                                      {conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption !==""?
                                    conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption
                                   :
                                   <img src={conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.uri} className={classes.fullWidth} alt="chat_image" />
                                   }   </div>
                                      <br/>
                                        
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={[messageText]}
                                          autoEscape={true}
                                          textToHighlight={row.caption}
                                        />
                                        </>
                                      : 
                                      <> 

                                   {row.format === "template_header" && row.uri !==""?
                                      <img src={row.uri} className={classes.fullWidth} alt="chat_image" />
                                      :""}
                                      <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={[messageText]}
                                      autoEscape={true}
                                      textToHighlight={row.caption}
                                      />
                                        </>
  
                                      
                                      : row.type === "file" &&  row.format === "image" ?

                                        <>
                                          <img src={`${process.env.REACT_APP_URL}`+row.url} className={classes.fullWidth} onClick={() => handleImageOpen(row.url)} alt="chat_image" />  <br/>
                                          <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[messageText]}
                                    autoEscape={true}
                                    textToHighlight={row.caption}
                                    />

                                        </>
                                        : 
                                        row.type === "file" &&  row.format ==="audio" ?

                                          <audio src={`${process.env.REACT_APP_URL}`+row.url} controls className={classes.fullWidth} />
                                          : row.type === "file" &&  row.format === "document" ?
                                            <>
                                              <a  href={`${process.env.REACT_APP_URL}`+row.url} target="_blank" rel="noreferrer" download>
                                                <iframe src={`https://docs.google.com/a/umd.edu/viewer?url=${process.env.REACT_APP_URL+row.url}&embedded=true`} title="File" width="100%" height="200" > </iframe>
                                                <br />

                                                <List >

                                                  <ListItem>
                                                    <ListItemAvatar>

                                                      <DescriptionIcon className={classes.descriptionIcon} />

                                                    </ListItemAvatar>
                                                    <div className={classes.lgButton}> 
                                                    <ListItemText
                                                      primary={row?.url.replace("assets/Documents/", '')}

                                                    />
                                                    </div>
                                                    <ListItemSecondaryAction>
                                                      <IconButton edge="end" aria-label="delete">
                                                        <GetAppIcon />
                                                      </IconButton>
                                                    </ListItemSecondaryAction>
                                                  </ListItem>

                                                </List></a>
                                            </>
                                            : row.format === "contact" ? 
                                              <>
<List >

<ListItem style={{ padding: "0px"}}>
  <ListItemAvatar>

  <Avatar alt="Image"    />
  </ListItemAvatar>
   <ListItemText
    primary={row.contactName} 
    secondary={row.contactNumber} 
  />
  
</ListItem>

</List>


                                              </> : row.type === "file" &&  row.format === "video" ?
                                                <>
                                                  <video controls width={"100%"}>
                                                    <source src={`${process.env.REACT_APP_URL}`+row.url} type="video/mp4" />

                                                  </video> <br/>
                                                  <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[messageText]}
                                    autoEscape={true}
                                    textToHighlight={row.caption}
                                    />
                                                </>

                                                : row.type === "file" &&  row.format === "sticker" ?

                                                  <>
                                                    <img src={`${process.env.REACT_APP_URL}`+row.url} className={classes.fullWidth} alt="chat_image" />

                                                  </>
 : row.type === "location"?

 <List >

 <ListItem>
   <ListItemAvatar>

     <RoomIcon  className={classes.descriptionIcon} />

   </ListItemAvatar>
   <div className={classes.lgButton}> 
   <ListItemText
     primary={<> {"Address: " + row.locationAddress}  <br/> {"Latitude: " + row.latitude} <br/>  {"Longitude: " +row.longitude} </>  }

   />
   </div>
  
 </ListItem>

</List>

                                                 :       row.replyMessageId?<> 
                                                  {conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption !==""?
                                                 <div className={classes.replyMessage}> 
                                                
                                   { conversations.filter(item => item?.waMessageId === row.replyMessageId)[0]?.caption} 
                                   </div>   : ""
                                    } 
                                                 <br/> 
                                                   <Highlighter
                                                     highlightClassName="YourHighlightClass"
                                                     searchWords={[messageText]}
                                                     autoEscape={true}
                                                     textToHighlight={row.caption}
                                                   />
                                                   </>
                                                 : 
                                                 <> 

                                     {row.format === "template_header" && row.uri !==""?
                                      <img src={row.uri} className={classes.fullWidth} alt="chat_image" />
                                      :""}
                                      <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={[messageText]}
                                      autoEscape={true}
                                      textToHighlight={row.caption}
                                      />
                                        </>

}
 

                                      <Grid container direction="row" spacing={2} className={[classes.space, classes.paperContainer]}> 
                                        <div   className={classes.bubbleSpan}  >   {moment(moment(row.created).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm a')}  </div>
                                        <div   className={classes.blueTick} >   {row.status === "delivered" ? <DoneAllIcon color="disabled" /> : row.status === "read" ? <DoneAllIcon color="primary" /> : ""}</div>

                                      </Grid>

                                    </div>



                                  </div>
                                </>


                                  : ""}


                            </>
                          )
                        })}

                        <Drawer
                          className={classes.drawer}
                          
                          anchor="right"
                          open={open.profile}
                          classes={{
                            paper: classes.drawerPaper,
                          }}
                          
                          onClose={() =>  setOpen({
                            ...open, profle:false})}
                        >
                          <div className={classes.drawerHeader}>
                            <IconButton onClick={(e) =>  setOpen({
                            ...open, profile:false})}>
                              <ChevronLeftIcon />
                            </IconButton>
                            <Typography variant="inherit" color="textSecondary">Contact Info</Typography>
                          </div>
                          <Divider />
                          <List className={classes.contactprofile}>
                            <Avatar alt="Image" src="" className={classes.contactprofileImg} />
                          </List>
                          <List className={classes.contactDetails}>
                            <Typography variant="h6" color="textSecondary" className={classes.title} >{row.name? row.name: row.phno}</Typography>
                            <Typography variant="body1" color="textSecondary" >{row.phno}</Typography>
                          </List>

                        </Drawer>


                        <Drawer
                          className={classes.drawer}
                          
                          anchor="right"
                          open={open.search}
                          classes={{
                            paper: classes.searchdrawerPaper,
                          }}
                          
                          onClose={() =>  setOpen({
                            ...open, search:false})}

                        >
                          <div className={classes.drawerHeader}>
                            <IconButton  onClick={() =>  setOpen({
                            ...open, search:false})}>
                              <ChevronLeftIcon />
                            </IconButton>
                            <Typography variant="inherit" color="textSecondary">Search</Typography>
                          </div>
                          <Divider />
                          <form ref={searchRef}>

                            <InputBase
                              sx={{ ml: 1, flex: 1 }}
                              inputProps={{ 'aria-label': 'search' }}
                              placeholder="Enter Search"
                              onChange={search}
                              variant="filled"
                            />


                          </form>

                          <Grid className={classes.messageAreaSingle} >

                            {messageUsers.filter(item => item.type === "text").map((row, index, arr) => {
 
                              return (
                                <>    {row.to_number === "" ? <>

                                  <div className={classes.message}>
                                    <PersonPinIcon className={classes.messageAvatar} />
                                    <div className={ classes.messagebubbles  } > 


                                    <> 

                                    {row.format === "template_header" && row.uri !==""?
                                      <img src={row.uri} className={classes.fullWidth} alt="chat_image" />
                                      :""}
                                      <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={[messageText]}
                                      autoEscape={true}
                                      textToHighlight={row.caption}
                                      />
                                        </>

 


                                      <Grid container direction="row" spacing={2} className={[classes.space, classes.paperContainer]}>
                                        <div className={classes.bubbleSpan}  >   {moment(moment(row.created).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm a')}</div>
                                        <div  className={classes.blueTick} >   {row.status === "delivered" ? <DoneAllIcon color="disabled" /> : row.status === "read" ? <DoneAllIcon color="primary" /> : ""}</div>

                                      </Grid>
                                    </div>
                                  </div>
                                </>
                                  : row.from_number === "" ? <>
                                    <div className={classes.messageRight} >
                                      <PersonPinIcon className={classes.messageRightAvatar} />


                                      <div className={classes.messageRightBubbles} >

                                      <> 

{row.format === "template_header" && row.uri !==""?

   <img src={row.uri} className={classes.fullWidth} alt="chat_image" />
: ""}
<Highlighter
highlightClassName="YourHighlightClass"
searchWords={[messageText]}
autoEscape={true}
textToHighlight={row.caption}
/>
  </>
 

                                        <Grid container direction="row" spacing={2} className={[classes.space, classes.paperContainer]}>
                                          <div className={classes.bubbleSpan}  >   {moment(moment(row.created).format('YYYY-MM-DD[T]HH:mm:ss[Z]')).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm a')} </div>
                                          <div className={classes.blueTick} >   {row.status === "delivered" ? <DoneAllIcon color="disabled" /> : row.status === "read" ? <DoneAllIcon color="primary" /> : ""} </div>

                                        </Grid>

                                      </div>



                                    </div>
                                  </>
                                    : ""}</>
                              );
                            })}

                          </Grid>
                        </Drawer>


                        <Drawer
                          className={classes.drawer}
                          
                          anchor="right"
                          open={open.tag}
                          classes={{
                            paper: classes.tagdrawerPaper,
                          }}
                          
                          onClose={() =>  setOpen({
                            ...open, tag:false})}

                        >
                          <div className={classes.drawerHeader}>
                            <IconButton  onClick={() =>  setOpen({
                            ...open, tag: false})}>
                              <ChevronLeftIcon />
                            </IconButton>
                            <Typography variant="inherit" color="textSecondary">Add New Tag</Typography>
                          </div>
                          <Divider />
                          

                        

                          <form onSubmit={onTagAdd.handleSubmit}  >

 
    <Grid container direction="row" spacing={2} className={classes.drawerContent}>
    <Grid item xs={12} >
    <InputLabel shrink htmlFor="tag_name">  Tag Name    </InputLabel>

<FormControl className={classes.margin}>
  <TextField
    classes={{ root: classes.customTextField }}
    InputProps={{ disableUnderline: true }}
    size="small"
    placeholder="Enter tag name"
    type="text"
    name="tag_name" 
    value={onTagAdd.values.tag_name}
    onChange={onTagAdd.handleChange}
    

  />

</FormControl>
 {onTagAdd.touched.tag_name? 
    <Typography variant="inherit" color="error">  {onTagAdd.errors.tag_name}</Typography>
:""}
      </Grid>
     

      
 
    </Grid>
   
   
    <Grid
      container
      direction="row"
      spacing={2}
  
      className={classes.drawerFooter}
    >
    

      <Button
        variant="contained"
        color="primary"
        size="small"
         type="submit"
         disabled={onTagAdd.isSubmitting}
      >
        Save
      </Button>

      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={ (e) =>{
          setOpen({
            ...open, tag:false})
        }}  
      >
        
        Close
      </Button>
    </Grid>
 
</form>

                          
                        </Drawer>


                        <div id={"section" + localStorage.getItem("mobile")}  > </div>

                      </List>

                      <Grid container className={classes.foot} >  
                       
                          {valid === true && view === "Message" ?
                           <form ref={messageRef}  className={classes.fullWidth} onSubmit={(e) => {
                            e.preventDefault();
                            sendMessage(mobile, row.instance_id)
                          }}  >
  
                            <>  
                            <Grid container direction="row" spacing={2}  className={classes.space}>
                            <Grid item  xs={12} lg={11}>
                             <Paper className={classes.paper} >

                              <InputBase required={true}  inputProps={{ 'aria-label': 'search' }} placeholder="Enter Message Here"
                                defaultValue={text} onChange={e => setText(e.target.value)} name='text' />

                            </Paper>
                            </Grid>

                            <Grid item xs={12} lg={1}  className={classes.drawerClose} > 
                              <Button aria-describedby={ids} variant="filled" color="primary" className={classes.button} onClick={handlePopoverClick}>
                                <AttachFileIcon color="primary" />
                              </Button> 
                              

                              <Button type='submit' color="primary"  className={classes.button} ><SendIcon /></Button>
                              </Grid>
                              </Grid>

                              <Popover
                                id={ids}
                                open={opens}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >


                                <Grid container direction="row" style={{padding: "0px 20px"}} className={[classes.fullWidth, classes.center]}>
                                  <Grid item xs={3} className={classes.photoLibrary} onClick={(e) => { handleImageClick() }}  >
                                    <FcGallery style={{ fontSize: "30px"}} /> <br />
                                    <input
                                      type="file"
                                      ref={hiddenImageInput}
                                      onChange={handleImageChange}
                                      className={classes.nothing}

                                      accept="image/jpeg, image/png"
                                    /> Picture</Grid>

                                 

                            <Grid item xs={3} className={classes.photoLibrary} onClick={(e) => { handleAudioClick() }}  >
                                    <FcAudioFile style={{ fontSize: "30px"}}  /><br />
                                    <input
                                      type="file"
                                      ref={hiddenAudioInput}
                                      onChange={handleAudioChange}
                                      className={classes.nothing}

                                      accept="audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg"
                                    /> Audio</Grid>


                     <Grid item xs={3} className={classes.photoLibrary} onClick={(e) => { handleVideoClick() }}  >
                                    <FcVideoFile style={{ fontSize: "30px"}}  /><br />
                                    <input
                                      type="file"
                                      ref={hiddenVideoInput}
                                      onChange={handleVideoChange}
                                      className={classes.nothing}

                                      accept="video/mp4, video/3gp"
                                    /> Video</Grid>

<Grid item xs={3} className={classes.photoLibrary} onClick={(e) => { handleDocumentClick() }}  >
                                    <FcDocument style={{ fontSize: "30px"}}  /><br />
                                    <input
                                      type="file"
                                      ref={hiddenFileInput}
                                      onChange={handleFileChange}
                                      className={classes.nothing}

                                      accept="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    /> Document</Grid>
                                </Grid>


                              </Popover>

                            </>

</form>
                            :
                            <>
                              <Grid container direction="row" spacing={2}  className={classes.space}>
                                
                                
                            <Grid item  xs={12} sm={10} lg={10}>
                            <TextField  multiline maxRows={5} minRows={1} disabled={true}   inputProps={{ 'aria-label': 'search',  }} 
                            value={"This is your first message for this contact today use general_message to start chat"} name='text' />

                            </Grid>
                                 

                                    <Grid item xs={12}  sm={2} lg={2}  className={classes.center} > 
                                  <Button onClick={handleClick } variant="contained" color="primary" size="small"  >Send Here</Button>
                                  </Grid>


                              </Grid>



                            </>}


                       
                      </Grid>



                      <SwipeableDrawer

anchor="bottom"
open={state["bottom"]}
onClose={toggleDrawer("bottom", false)}
onOpen={toggleDrawer("bottom", true)}
classes={{ paper: classes.drawer }}
> 

{list("bottom", row?.instance_id)} 

</SwipeableDrawer>


                    </TabPanel>
                  </>

                );
              })}


       
              <Dialog
                onClose={handleImageClose}
                open={imageOpen}  
                className={classes.modalSize}
                  >
                 
 
                  <img src={`${process.env.REACT_APP_URL}` + imageUrl} className={classes.modalImage} alt="chat_image" />
                
              </Dialog>
             


            </Grid>
            </> 
           : "" }
          </Grid>
        </div>
      </div>



      <Backdrop className={classes.backdrop} open={loader}  >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiThemeProvider>




  );
}

export default Home;