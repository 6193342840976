import { api } from "./api";
 


export const saveTemplates = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("templates/saveTemplates", {
        instance_id: data.instance_id, 
    });
    return response;
  }; 
  
  export const viewAllTemplates = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("templates/viewAllTemplates", {
        instance_id: data.instance_id, 
    });
    return response;
  };


  export const viewApprovedTemplates = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("templates/viewApprovedTemplates", {
        instance_id: data.instance_id, 
    });
    return response;
  };
 
  export const viewTemplate = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("templates/viewTemplate", {
      id: data.id, 
        instance_id: data.instance_id, 
    });
    return response;
  };


 