import React, { useEffect, useState } from "react";

import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

import { ToastContainer } from "react-toastify"; 
import { useTheme } from "@material-ui/styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import CloseIcon from "@material-ui/icons/Close";
 
// import Dashboard from "../../pages/dashboard"; 
 import Users from "../../pages/superAdmin/Users"; 
 import Transactions from "../../pages/superAdmin/Transaction";


 import Transaction from "../../pages/admin/Transaction";
import Instance from "../../pages/admin/Instance";
import Campaign from "../../pages/admin/Campaign"; 
import GroupContact from "../../pages/admin/GroupContact"; 
import Group from "../../pages/admin/Group";
import Contact from "../../pages/admin/Contact";
import SearchTag from "../../pages/admin/SearchTag";

import Referral from "../../pages/admin/Referral";

import ReplyMessages from "../../pages/admin/Reply"; 


// context
import { useLayoutState } from "../../context/LayoutContext";
import useStyles from "./styles";

function Layout(props) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var layoutState = useLayoutState();
  const user = JSON.parse(localStorage.getItem("user"));
 
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
  handleWindowWidthChange();
  return function cleanup() {
    window.removeEventListener("resize", handleWindowWidthChange);
};
});

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    // var breakpointWidth = theme.breakpoints.values.md
    var breakpointWidth = theme.breakpoints.values.sm 
    var isSmallScreen = windowWidth < breakpointWidth
     if (isSmallScreen && isPermanent) {
    setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
    setPermanent(true);
    }
    }

  const getMuiTheme = () => createTheme({

    overrides: {
      
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiAvatar:{
        root:{
          fontFamily: "'Satoshi'"
        }
      },
      MuiMenuItem:{
        root:{
          fontFamily: "'Satoshi'"
        }
      },
      MuiTable:{
        root:{
          "& caption":{ fontFamily: "'Satoshi' !important",
          }
        }
      },
      MuiInput:{
      root:{
        border: "1px solid #ced4da"
      },
      underline:{
        "&:before":{
          borderBottom: "none !important"
        },
        "&:after":{
          borderBottom: "none !important"
        },
      }
      },
      MuiInputBase: {
      
        input: {
          width: "100%",
         // border: "none",
          fontSize: "13px",
          display: "block",
           
          borderRadius: "4px",
        },
      }, 
      MuiPaper: {
        elevation4: {
          boxShadow: "none", 
        },
        elevation1: {
          boxShadow: "none", 
        },
      },

      MuiTableCell: {
        head: {
          color: "#121224",
          backgroundColor: "#cbe8ff !important", //new change f5f5f5
          fontSize: "16px !important",
          fontWeight: "bold",
          letterSpacing: "0.02em"
        },
        body: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "14.5px !important",
          "&:last-child": { whiteSpace: "nowrap",
          },
          root: {
            padding: "14px",
            fontFamily: "'Satoshi' !important",
           
          },
        },
      },
      MUIDataTable:{
        responsiveBase:{
          boxShadow: "0px 20px 20px 5px #2f3c4a14 !important"
        }
      },
      MuiAppBar:{
        colorPrimary:{
         // backgroundColor: "#064be2 !important", 
          backgroundColor: "#2196f3 !important", 
          
        },
         
      },
      MuiTab:{
        textColorPrimary:{
          "&$selected": {       
            color: "#006494", 
          },
        },
       
      },
      MuiTreeItem:{
        iconContainer:{
          width: "5px",
        }
      },
      MuiListItemAvatar:{
        root:{
         minWidth: "50px",
         
        }
      },
      MuiDialogActions:{
        root:{
          justifyContent: "center !important"
        }
      },

      MuiSvgIcon:{
      root:{
        width: "1.15em",
       height: "1.15em"
      }
      },
     
      MUIDataTableToolbar: {
        actions: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        icon: {
         // color: "#064be2",
          color: "#2196f3",
          
          "& svg": {
            color: "white",
            width: "25px",
            cursor: "pointer",
            height: "25px",
            padding: "5px",
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
            borderRadius: "100%",
            //backgroundColor: "#064be2",
            backgroundColor: "#2196f3",
          },
        },

        iconActive: {
         // color: "#064be2",
          color: "#2196f3",
          "& svg": {
            color: "white",
            width: "25px",
            cursor: "pointer",
            height: "25px",
            padding: "5px",
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
            borderRadius: "100%",
           // backgroundColor: "#064be2",
            backgroundColor: "#2196f3",
          },
        },
      },
     
      MuiTypography: {
        subtitle1: {
          fontWeight: "500",
          fontFamily: "'Satoshi' !important",
        },
        body1: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "14px",
        },
        body2: {
          color: "#121224",
          fontFamily: "'Satoshi' !important",
          fontSize: "13px",
        }, 
        text: {
          fontFamily: "'Satoshi' !important",
        },
        h1: {
         
          fontFamily: "'Satoshi' !important",
        },
        h2: {
        
          fontFamily: "'Satoshi' !important",
        },
        h3: { 
         
          fontFamily: "'Satoshi' !important",
        },
        h4: {
           
          fontFamily: "'Satoshi' !important",
        },
        h5: {
          
          fontFamily: "'Satoshi' !important",
        }, 
        h6: {
          fontSize: "18px",
          fontFamily: "'Satoshi' !important",
        }, 
      },
      MuiAutocomplete: {
        input: {
          width: "100% !important",
        },
      },  
      MuiFilledInput: {
          root: {
            width: "100%",
            display: "block",
            padding: "0px 25px 0px 0px !important",
            position: "relative",
            fontSize: "13px",
            marginTop: "24px",
           
            backgroundColor: "white",
            "&:hover": { backgroundColor: "unset !important",
            },
            "&.Mui-focused": { backgroundColor: "unset !important",
            },
          },

           
        },
        MuiSelect:{
          select:{
           // padding: "10px !important",
          }
        },
      MuiButton: { 
        startIcon:{
          marginRight: "4px !important"
        },
        root:{ 
            textTransform: "initial !important",
            fontFamily: "'Satoshi' !important",
            fontSize: "14px",
            fontWeight: 500,
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3) !important",
            "@media (max-width:959.95px)": {
              fontSize: "10px !important",
            }, 
        },
        label: {
          fontFamily: "'Satoshi' !important",
          fontSize: "14px",
          "@media (max-width:959.95px)": {
            fontSize: "10px !important",
          },
        },
        containedPrimary:{ 
        //  backgroundColor: "#064be2 !important",   
          backgroundColor: "#2196f3 !important", 
          textTransform:  "initial !important", 
          '&:active':{
           // backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#fff !important",
          },
          '&:hover':{
            //backgroundColor: "#064be2 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#fff !important",
          },
          '&:disabled':{
           // backgroundColor: "#064be2c7 !important",
            backgroundColor: "#2196f3 !important", 
            color:"#e4e4e4 !important",
          }
      },
      contained:{ 
       
        '&:disabled':{
         
           color:"e4e4e4 !important",
        }
    },
      containedSizeSmall:{
        textTransform:  "initial !important", 
        padding:  "4px 10px !important",
        fontWeight: "300 !important",
      },
      containedSecondary:{ 
        backgroundColor: red[500] +"!important",
        '&:active':{
          backgroundColor: red[500] +"!important",
          color:"#fff !important",
        },
        '&:hover':{
          backgroundColor: red[500] +"!important",
          color:"#fff !important",
        },
        '&:disabled':{ 
          color:"#e4e4e4 !important",
       }
      },
      },
      MuiFormLabel: {
        root: {
         fontFamily: "'Satoshi' !important",
          fontSize: "17px",
          "@media (max-width:959.95px)": { fontSize: "15px !important",
          },
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
      MuiTooltip:{
        tooltip:{
          fontFamily: "'Satoshi' !important",
        },
        // popper:{
        //   top: "-34px !important",
        //   left: '-45px !important'
        // }
      },
      MuiOutlined: {
        MuiChip: {
          avatar: { margin: "0px",
          },
        },
      },
      MuiListItemText:{
        root:{
          textWrap: "balance"
        }
      },
      MuiTabScrollButton:{
      root:{
        background: "white",
        color: "black",
        borderRadius: "30px",
        height: "40px",
        alignItems: "center",
        placeSelf: "center"
      }
      },
      PrivateSwipeArea:{
        root:{
          display: "none"
        }
      }
    },
  
  });

  return (

    <MuiThemeProvider theme={getMuiTheme()}>


    <div className={classes.root}>
        <>
         
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: isPermanent&&!layoutState.isSidebarOpened,
              [classes.contentShift1]: layoutState.isSidebarOpened,
            })}
          >
                  <div className={classes.fakeToolbar}> <Header history={props.history} />
            </div>
            <Switch>
            {/* <Route path="/app/dashboard" role={user.role_code} roles={["SUPERADMIN", "ADMIN"]} component={Dashboard} /> */}
              <AuthRoute path="/app/users" role={user?.role_code} roles={["SUPERADMIN"]} component={Users} />  
              <AuthRoute path="/app/transactions" role={user?.role_code} roles={["SUPERADMIN"]} component={Transactions} /> 
              <AuthRoute path="/app/transaction" role={user?.role_code} roles={["ADMIN"]} component={Transaction} />  
             <AuthRoute path="/app/instance" role={user?.role_code} roles={["ADMIN"]} component={Instance} />  
              <AuthRoute path="/app/group" role={user?.role_code} roles={["ADMIN"]} component={Group} />  
              <AuthRoute path="/app/contact" role={user?.role_code} roles={["ADMIN"]} component={Contact} />  
              <AuthRoute path="/app/groupContact" role={user?.role_code} roles={["ADMIN"]} component={GroupContact} />    
              <AuthRoute path="/app/campaign" role={user?.role_code} roles={["ADMIN"]} component={Campaign} />  
              <AuthRoute path="/app/referral_transaction" role={user?.role_code} roles={["ADMIN"]} component={Referral} />  
              <AuthRoute path="/app/search_tag" role={user?.role_code} roles={["ADMIN"]} component={SearchTag} />   
              <AuthRoute path="/app/reply_messages" role={user?.role_code} roles={["ADMIN"]} component={ReplyMessages} />  

            </Switch>
            {/* <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Flatlogic
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/about'}
                  target={'_blank'}
                  className={classes.link}
                >
                  About Us
                </Link>
                <Link
                  color={'primary'}
                  href={'https://flatlogic.com/blog'}
                  target={'_blank'}
                  className={classes.link}
                >
                  Blog
                </Link>
              </div>
              <div>
                <Link
                  href={'https://www.facebook.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="facebook">
                    <Icon
                      path={FacebookIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://twitter.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton aria-label="twitter">
                    <Icon
                      path={TwitterIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
                <Link
                  href={'https://github.com/flatlogic'}
                  target={'_blank'}
                >
                  <IconButton
                    aria-label="github"
                    style={{marginRight: -12}}
                  >
                    <Icon
                      path={GithubIcon}
                      size={1}
                      color="#6E6E6E99"
                    />
                  </IconButton>
                </Link>
              </div>
            </Box> */}
          </div>
        </>
    </div>

    <ToastContainer
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        hideProgressBar={true}
      />

    </MuiThemeProvider>

  );
}

export default withRouter(Layout);

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

function AuthRoute({ component, role, roles = [], ...rest }) {

  return (
    <Route
      {...rest}
      render={(props) =>
        roles.includes(role) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location, },
            }}
          />
        )
      }
    />
  );
}
