import React, { useState, useEffect, useRef, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button,
  List,
  Box,
  SwipeableDrawer, 
  Tooltip, 
  Typography, 
  Backdrop,
  CircularProgress, 
  TextField,
  TablePagination,
  Switch
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import PageTitle from "../../components/PageTitle/PageTitle";
import useStyles from "../../themes/style.js";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification"; 
import { Visibility as ViewIcon, Close as CloseIcon, } from "@material-ui/icons";
import { viewUser, viewAllUsers, changeUserState } from '../../services/user.api'; 
import useMediaQuery from '@material-ui/core/useMediaQuery'; 
import "react-toastify/dist/ReactToastify.css";

export default function Users(props) {
  var moment = require('moment-timezone');

  const classes = useStyles();
  const mobileQuery = useMediaQuery('(max-width:600px)');  
  const [userData, setUserData] = useState([]); 
  const [userView, setUserView] = useState({ 
    data:""
  });
 
  const [loader, setLoader] = useState(false); 
  const filterRef = useRef(null);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [referedBy, setReferredBy] = useState("");
  const [page, setPage] = useState(0);
  const [currerntPage, setCurrerntPage] = useState(1);
  const [count, setCount] = useState(0);
  const token= localStorage.getItem('token');
  const [reducerValue,  forceUpdate ] = useReducer((x) => x + 1, 0);
 
  const HeaderElements = () => <>Total : {count}</>;

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    setPage(newPage);
    setCurrerntPage(newPage + 1);
    const form = filterRef.current;
    
    viewAllUsers({
      page: newPage + 1, 
      referedBy: `${form["referedBy"].value}`,
      fromDate: `${form["fromDate"].value}`,
      toDate: `${form["toDate"].value}`,
      token: token,
    }).then((response) => {    
      if (response.data.status === true) { 
        
        setUserData(response.data.data);
           setCount(response.data.count);
           setLoader(false);

      }  else{
        setCount(0);
        setUserData([]);
       setLoader(false);
     }
       
    }) 
    .catch(function (error) {
     console.log(error);
   })

  };

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
       
      viewAllUsers({page: 1, token: token,}).then((response) => {    
         if (response.data.status === true) { 
           
           setUserData(response.data.data);
              setLoader(false);
              setCount(response.data.count);
         }   else{
          setCount(0);
          setUserData([]);
         setLoader(false);
       }
       }) 
       .catch(function (error) {
        console.log(error);
      })
 
    };
    fetchData();
       
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [reducerValue]);

  

  function handleshow(values){
    setLoader(true); 
    viewUser({id: values, token: token,}).then((response) => {    
      if (response.data.status === true) { 
         
        setUserView({
          ...userView,
          data: response.data.data 
          });
  
            setState({ ...state, right: true });
            setLoader(false);
          } 
        }) 
          .catch(function (error) {
           console.log(error);
         })
    
  }
   
 
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }
   
function handleStatus(id, value) { 
      setLoader(true); 
      changeUserState({id: id, token: token,}).then((response) => {    
        if (response.data.status === true) { 
           
          const switchState = userData.map(item => { 
            if (item.id === id) {
              return { ...item, isActive: value===true?"1":"0" };
               
            }
            return item;
          }); 
          setUserData(switchState);
              setLoader(false);
              handleNotificationCall("success", response.data.message);
            }  else{
              handleNotificationCall("error", response.data.message);
            }
          }) 
            .catch(function (error) {
             console.log(error);
           })
}


const handleReferredByChange= (event) => {
  setReferredBy(filterRef.current.fromDate.value);
};

  const handleFromDateChange = (event) => {
    setFromDate(filterRef.current.fromDate.value);
  };

  const handleToDateChange = (event) => {
    setToDate(filterRef.current.toDate.value);
  };

  function getFilterData() {
    setLoader(true);
    
    const form = filterRef.current;
      
    viewAllUsers({
      page: 1, 
      referedBy: `${form["referedBy"].value}`,
      fromDate: `${form["fromDate"].value}`,
      toDate: `${form["toDate"].value}`,
      token: token,
    }).then((response) => {    
      if (response.data.status === true) { 
        setCount(response.data.count);
        setUserData(response.data.data);
           setLoader(false); 
      }  else{
         setCount(0);
         setUserData([]);
        setLoader(false);
      }
     
     
    }) 
    .catch(function (error) {
     console.log(error);
   }) 
           
  }
 
  const resetForm = (e) => {
    filterRef.current.reset(); 
    forceUpdate();
  };

  const [state, setState] = useState({  right: false });

  const toggleDrawer = (anchor, open) => (event) => {  
    setState({ ...state, [anchor]: open });
  };


  const list = (anchor) =>
     (
      <>
        <Box sx={{ width: "100%" }} role="presentation">
          <List>
            <Card className={classes.root}>
              <CardHeader>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  className={classes.drawerHeader}
                >
                  <Typography variant="subtitle1"> View User</Typography>

                  <Grid className={classes.drawerClose}>
                    <CloseIcon
                      className={classes.closeBtn}
                      size="14px"
                      onClick={toggleDrawer(anchor, false)}
                    />
                  </Grid>
                </Grid>
              </CardHeader>

              <CardContent className={classes.drawerContent}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}> <Typography className={classes.boldtext}>  First Name   </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> {userView.data?.firstname}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}> Last Name  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>  {userView.data?.lastname}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}> Referal Code </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>  {userView.data?.referal_code}  </Grid>

 
                  <Grid item xs={12} sm={6} md={6} lg={6}>  <Typography className={classes.boldtext}>  Email  </Typography> </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {userView.data?.email}  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Mobile  </Typography>  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {userView.data?.country_code + " " +userView.data?.mobile}   </Grid>
                
                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Status  </Typography>  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {userView.data?.isActive === "1"?   
                  <Button variant="contained" size="small" color='primary'  className={classes.pointerEventsNone} > ACTIVE </Button> 
                  : <Button variant="contained" size="small" color='secondary' className={classes.pointerEventsNone}   > INACTIVE </Button> }   </Grid>
 
                  <Grid item xs={12} sm={6} md={6} lg={6}>   <Typography className={classes.boldtext}>  Referred By  </Typography>  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>   {userView.data?.referedBy}   </Grid>
 
                  <Grid item xs={12} sm={6} md={6} lg={6}>  <Typography className={classes.boldtext}>    Posted Date </Typography>  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}> { moment(userView.data?.created).tz(moment.tz.guess()).format('DD-MM-YYYY')}    </Grid> 
               
                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.drawerFooter}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    
                    Close
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </List>
        </Box>
      </>
    ) ;

  return (
    <>
      <Grid container direction="row" spacing={2} className={classes.heading}>
        <Grid item xs={9} sm={7} md={8} lg={6}>
          
          <PageTitle title="Users" />
        </Grid>

        <Grid item xs={3} sm={5} md={4} lg={6} className={classes.drawerClose}>
          
          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: classes.drawer }}
            disableSwipeToOpen={false}
          >
            {list("right")}
          </SwipeableDrawer>
        </Grid>
      </Grid>



      <form
        ref={filterRef}
        onSubmit={(e) => {
          e.preventDefault();
          getFilterData();
        }}
      >
        <Grid container spacing={2} className={classes.filterGap}>
         
        <TextField
            name="referedBy"
           label="Referred By"
            InputLabelProps={{ shrink: true }}
            classes={{ root: classes.filterFullWidth }}
            defaultValue={referedBy}
            onChange={handleReferredByChange}
          />

          <TextField
            name="fromDate"
           label="From"
            InputLabelProps={{ shrink: true }}
            classes={{ root: classes.filterFullWidth }}
            type="date"
            defaultValue={fromDate}
            onChange={handleFromDateChange}
          />

          <TextField
            name="toDate"
           label="To"
            InputLabelProps={{ shrink: true }}
            classes={{ root: classes.filterFullWidth }}
            type="date"
            defaultValue={toDate}
            onChange={handleToDateChange}
          />

          <div className={classes.buttons}>
            <Button
              variant="contained"
              size="small"
              color="primary" 
              type="submit"
            >
              Search
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => resetForm()}
            >
              Reset
            </Button>
          </div>
        </Grid>
      </form>



      <Grid container spacing={2}>
        <Grid item xs={12}>
 
          
          <MUIDataTable
            options={{
              pagination: false,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
              responsive: mobileQuery===true? 'vertical' : 'standard',
              customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: 'Oops! Matching record could not be found',
                }
              }
            }}
            columns={[
              {
                name: "S.No",
              },
              {
                name: "Name",
              },
              
              {
                name: "Referal Code",
              },
              {
                name: "Email",
              },

              {
                name: "Mobile",
              }, 
              {
                name: "Status",
              }, 
              {
                name: "Posted Date",
              },
              {
                name: "Referred By",
              },
              {
                name: "Actions",
              },
            ]}
            data={userData.map((item, index) => {
               return [
                currerntPage !== 0 ? 10 * currerntPage - 10 + index + 1   : index + 1 , 
                item.firstname + " " + item.lastname, 
                item.referal_code,
                item.email,
                item.country_code + " " +item.mobile,
                <Switch
                checked={item.isActive === "1"? true: false }
                onChange={(e) => {
                   handleStatus(item.id, e.target.checked);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }} />,
                moment(item.created).tz(moment.tz.guess()).format('DD-MM-YYYY'),
                item.referedBy,
                <>
                  <Grid container className={classes.space}>
                    <Grid item xs className={classes.toolAlign}>
                    
                      <Tooltip
                        title="View User"
                        placement="bottom"
                        aria-label="view"
                      >
                        <ViewIcon
                          className={classes.toolIcon}
                          onClick={(e) => {

                            handleshow(item.id);
                           

                          }}
                        />
                      </Tooltip>
                      
                    </Grid>
                  </Grid>
                </>,
              ];
            })}
          />

          <Grid container spacing={2} className={classes.pagination}>
            <TablePagination
              rowsPerPageOptions={[50]}
              component="div"
              count={count}
              rowsPerPage={50}
              page={page}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
      </Grid>

      

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

