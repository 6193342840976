import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  link: {
    textDecoration: "none",
    padding: "14px 8px", 
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  externalLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none'
  },
  linkActive: {
    backgroundColor: theme.palette.primary.main,
    
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: "#FFFFFF",
    },
  },
  linkIcon: {
    marginRight: theme.spacing(0),
    color: theme.palette.text.secondary + "99",
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    justifyContent: "center",
   
    "& button":{
      // padding: "7px", 
      // background: "white",
      "&:hover":{
       // backgroundColor: "white !important"
      },
      "& span":{
        "& img":{
          width: 30
        },
        "& svg":{
          fontSize: "27px !important",
          
       },
      }
    }
  },
  linkIconActive: {
    color: "#2196f3 !important", 
    
  },
  linkText: {
    padding: 0,
    color: "#fff",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
  },
  linkTextActive: {
    color: "#fff",
    
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 30,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },

  
  sidedropdown:{
    cursor: "pointer",
    position: "fixed", 
     left: "160px", 
     backgroundColor:"white",
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)", 
    top: "290px",
    zIndex:"999",
  },
 

  sidedropdownList: { 
    margin:"0px 10px 0px 0px",   
    padding:"0px",    
    cursor: "pointer",
    color: "#fff !important",
    
    "&:hover":{ 
      backgroundColor: "#2196f3 !important", 
      // boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12) !important",
      // "& $subMenulinkIcon":{
      //   "& button":{
      //     color: "#fff !important",
      //   },
       
      // },
      "& $linkText":{
        color: "#fff !important",
      },
    },
  },
}));
