import { api } from "./api";
 
 

export const getAllMessages = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("instance/getAllMessages", {
      phone_number: data.phone_number,
    });
    return response;
  };
 

  export const markMessageRead = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("recive/markMessageRead", {
      mobile_number: data.mobile_number,
    });
    return response;
  };
 

  export const viewAllMessageContacts = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    
    try { 
     const response = await api.post('contact/viewAllMessageContacts', { });
     return response; 
     
   } catch (err) {
      console.error(err.message);
   }
  
 };

  