 
import { api } from "./api";
 

export const addTag = async (data, value) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("tags/addTag", {
        tag_name: data.tag_name, 
    });
    return response;
  }; 
  
  export const viewAllTags = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    try { 
     const response = await api.post('tags/viewAllTags', { });
     return response;  
   } catch (err) {
      console.error(err.message);
   } 
 };


