import { api } from "./api";

 
export const addCampaign = async (data) => {
   
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'

    const response = await api.post("campaign/addCampaign", {
      instance_id :  data.instance_id, 
      campaign_name: data.campaign_name, 
      template_id: data.template_id,
      group_id: data.group_id, 
      message: data.message, 
      vars: data.vars,
      headerType: data.headerType,
      fileLink: data.header,
      schedule: data.schedule,
      type: data.type,
    });
    return response;
  };
 

export const viewCampaign = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("campaign/viewCampaign", {
        id: data.id,
    });
    return response;
  };

  export const viewAllCampaign = async (data) => { 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    
     try { 
      const response = await api.post('campaign/viewAllCampaigns', {}); 
      return response;

    } catch (err) {
       console.error(err.message);
    }
   
  };

 

  export const rerunCampaign = async (data) => { 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("campaign/rerunCampaign", { 
      campaign_id: data.campaign_id,  
    });
    return response;
  };