import React from "react";
import jwt_decode from "jwt-decode";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

 
export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, authUser, superUser };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    setTimeout(() => {
       setError(null)
      setIsLoading(false)
      dispatch({ type: 'LOGIN_SUCCESS' })

      history.push('/app/instance')
    }, 2000);
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("token"); 
  localStorage.removeItem('user'); 
  localStorage.removeItem('instanceId'); 
   dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
}



function authUser(authToken, dispatch, history){ 
  localStorage.setItem('token', authToken); 

  const decode = jwt_decode(authToken); 
  localStorage.setItem('user', JSON.stringify(decode)); 
  
  history.push('/app/instance');    
  dispatch({ type: 'LOGIN_SUCCESS' }) 


 
 
}
 
function superUser(authToken, dispatch, history){ 

  localStorage.setItem('token', authToken); 

  const decode = jwt_decode(authToken); 
  localStorage.setItem('user', JSON.stringify(decode) ); 
  history.push('/app/instance');    
  dispatch({ type: 'LOGIN_SUCCESS' }) 
 
}


