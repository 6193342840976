import { api } from "./api";
 


export const addContact = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("contact/addContact", {
      name: data.name,
       email: data.email,
       mobile: value.mobile.slice(value.countryCode?.length),
       countryCode:value.countryCode
    });
    return response;
  };

export const editContact = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
   const response = await api.post("contact/editContact", {
    id: value.id,
    name: data.name,
    email: data.email,
    mobile: value.mobile.slice(value.countryCode?.length),
    countryCode:value.countryCode
  });
  return response;
};


export const deleteContacts = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("contact/deleteContacts", {
    contacts: data.contacts, 
 });
 return response;
};

export const viewContact = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("contact/viewContact", {
        id: data.id,
    });
    return response;
  };

  export const viewAllContact = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
     try { 
      const response = await api.post('contact/viewAllContacts', { });
      return response; 
      
    } catch (err) {
       console.error(err.message);
    }
   
  };

 



  
export const setContactTag = async (data) => {
 
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("contact/setContactTag", {
      tag_id: data.tag_id,
      message_contact_id: data.message_contact_id, 
    });
    return response;
  };
 

export const removeContactTag = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("contact/removeContactTag", {
    id: data.id, 
 });
 return response;
};


export const viewAllContactTags = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("contact/viewAllContactTags", {
    phoneNumber: data.phoneNumber, 
 });
 return response;
};




export const getContactsByTag = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("contact/getContactsByTag", {
    tag_id: data.tag_id, 
 });
 return response;
};