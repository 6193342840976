import { api } from "./api";
 
  
export const viewUser = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  try { 
       
    const response = await api.post('user/viewUser', {
      id: data.id
    });  
    return response;

  } catch (err) {
     console.error(err.message);
  }
   
   

  };

  export const viewAllUsers = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
     try { 
       
      const response = await api.post('user/viewAllUsers', { 
        page: data.page,
        referedBy: data.referedBy,
        fromDate: data.fromDate,
        toDate: data.toDate,
       });    
      return response;

    } catch (err) {
       console.error(err.message);
    }
   
  };
 

  export const profile = async (data, value) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("user/register", {
        fbBaseUrl: data.fbBaseUrl,
        phoneNumber : data.phoneNumber,
        waToken: data.waToken,
        waId: data.waId, 
    });
    return response;
  };


  
export const changeMyPassword = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("auth/changeMyPassword", {
    password: data.password,
    newPassword: data.confirm,
    oldPassword: data.old 
  });
  return response;
};
 

export const changeUserState = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("user/changeUserState", {
    id: data.id, 
  });
  return response;
};



export const verifyEmail = async (data) => {
 
  const response = await api.post("user/verifyEmail", {
    token: data.token, 
  });
  return response;
};

 

export const updateProfile = async (data, value) => {
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
   const response = await api.post("user/updateProfile", {
    id: value.id,
    firstname: data.firstName,
    lastname : data.lastName, 
     mobile: value.mobile.slice(value.countryCode?.length),
    country_code: value.countryCode,
    address: data.address,
  });
  return response;
};