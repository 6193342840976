// import { makeStyles } from "@material-ui/styles";

// export default makeStyles(theme => ({
//   root: {
//     display: "flex",
//     maxWidth: "100vw",
//     overflowX: "hidden",
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     backgroundColor: "white",
//     width: `calc(100vw - 240px)`,
//     minHeight: "100vh",
//   },
//   contentShift: {
//     width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   },
//   fakeToolbar: {
//     ...theme.mixins.toolbar,
//   },
//   link: {
//     '&:not(:first-child)': {
//       paddingLeft: 15
//     }
//   }
// }));



import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    [theme.breakpoints.down("md")]: { 
      display: "block", 
    }
  },
  content: {
    flexGrow: 1, 
    minHeight: "100vh",
    backgroundColor: "white",
    padding: "0px 20px",
     
  },
  contentShift: {
    width: `calc(100vw - ${187 + theme.spacing(6)}px)`,
    
    [theme.breakpoints.only("md")]: {  
      paddingLeft:"80px", 
    },
   
    [theme.breakpoints.between("xs", "md")]: { 
       width: "100%",  
    },
     
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  contentShift1:{
    width: `calc(100vw - ${187 + theme.spacing(6)}px)`,
    [theme.breakpoints.only("xs")]: { 
      width: "100%", 
    },
    [theme.breakpoints.between("sm", "md")]: { 
      width: "100%", 
      paddingLeft:"200px", 
    },
     
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }, 
  fakeToolbar: {
    ...theme.mixins.toolbar,
    margin:"10px"
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  },

}));

