import React, { useState } from "react";
import {
  Grid, Typography, Button, IconButton, InputAdornment,
  Backdrop, CircularProgress, TextField,   InputLabel,  Avatar, 
} from "@material-ui/core";
import { withRouter, useHistory } from "react-router-dom";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useStyles from "./styles"; 
 import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
 import CloseIcon from '@material-ui/icons/Close'; 
import { changePassword } from '../../services/auth.api';  
import { forgetPasswordSchema } from "../../components/Yup/Yup";
import Lottie from "lottie-react";
import animation from '../../images/chat.json';
 import Notification from "../../components/Notification/Notification";
import useGlobalStyles from "../../themes/style";
import { FcBusinessman } from "react-icons/fc";
import logo from '../../images/infoapto.png';

function Forget(props) { 
  var classes = useStyles();
   const [loader, setLoader] = useState(false);  
   var globalClasses= useGlobalStyles(); 
   const history = useHistory(); 
  const [values, setValues] = React.useState({ 
    showPassword: false,
    showConfirmPassword: false
  });
 
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.confirm });
  }; 
  
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }


  const onChangePassword = useFormik({
    initialValues: {   
      password:  '',  
      confirm: '',  
    },
     validationSchema:  forgetPasswordSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  

      if (values.password === values.confirm) {

      setLoader(true);
      changePassword({ verify_id: props.match.params.id, password: values.password }).then((response) => {    
        if (response.data.status === true) {
        handleNotificationCall("success", response.data.message);  
         
        resetForm();
        history.push('/login');
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false);
       setLoader(false);
     })
     .catch(function (error) {
      console.log(error);
    })
  } else{
    handleNotificationCall("error", "Password is mismatch");
   
  }
    },

   
  });  

     

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
      <Lottie animationData={animation} loop={true}  className={classes.fullImg} />
        <Typography className={classes.logotypeText}>  </Typography>
      </div>
     
      <div className={classes.formContainer}>
        <div className={classes.form}>
        
        <Avatar  className={classes.large} >
            <FcBusinessman style={{ fontSize: "70px"}} />
          </Avatar>
          <Typography  className={classes.loginText} > <img src={logo} alt="bg"   />  </Typography>
           <React.Fragment>
 
           <form onSubmit={onChangePassword.handleSubmit}  >

    <Grid container direction="row" spacing={2}>

   

<Grid item xs={12} >


<InputLabel shrink htmlFor="password"> Password </InputLabel>
 
<TextField
                    id="password"
                    InputProps={{
                      classes: {
                        underline: globalClasses.textFieldUnderline,
                        input: globalClasses.textField,
                      }, 
                      endAdornment: <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}   >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    }}
                    name="password"
                    margin="normal"
                    placeholder="New Password"
                    type={values.showPassword ? 'text' : 'password'}
                    fullWidth
                    classes={{ root: globalClasses.loginTextField }}
                    value={onChangePassword.values.password}
                    onChange={onChangePassword.handleChange}
                    
                    error={onChangePassword.touched.password && Boolean(onChangePassword.errors.password)}
                    helperText={onChangePassword.touched.password && onChangePassword.errors.password}
                  />

</Grid>


<Grid item xs={12}  >


<InputLabel shrink htmlFor="confirm"> Confirm Password  </InputLabel>



<TextField
                    id="confirm"
                    classes={{ root: globalClasses.loginTextField }}
                    InputProps={{
                      classes: {
                        underline: globalClasses.textFieldUnderline,
                        input: globalClasses.textField,
                      }, 
                      endAdornment: <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickConfirmPassword}   >
                        {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                    }}
                    name="confirm"
                    margin="normal"
                    placeholder="Confirm Password"
                    type={values.showConfirmPassword ? 'text' : 'password'}
                    fullWidth
    
                    value={onChangePassword.values.confirm}
                    onChange={onChangePassword.handleChange}
                    
                    error={onChangePassword.touched.confirm && Boolean(onChangePassword.errors.confirm)}
                    helperText={onChangePassword.touched.confirm && onChangePassword.errors.confirm}
                  />
 
</Grid>



   </Grid>
    


   <div style={{justifyContent: "center", display:"flex",marginTop: "10px"}}>
                    {loader ? (
                      <CircularProgress size={26} className={classes.loginLoader} />
                    ) : (
                        <Button
       variant="contained"
       color="primary"
       size="small"
       type="submit"
       disabled={onChangePassword.isSubmitting}
     >
       Save
     </Button>
                    )}
                  

                    </div>
 
</form>
                </React.Fragment>
        
        </div>
        
      </div>

  <ToastContainer
  closeButton={
  <CloseButton className={classes.notificationCloseButton} />
  }
  closeOnClick={false}
  hideProgressBar={true}
  />


<Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Grid> 
  );
}

export default withRouter(Forget);
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
} 