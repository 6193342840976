import { makeStyles } from "@material-ui/styles";
import wave from '../../images/wave.png';

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    [theme.breakpoints.up("sm")]: {
    "&:before": {
      content: "''",
     backgroundImage: "url('"+ wave +"')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      position: "absolute",
      left: "0px",
      top: "0px",
      width: "100%",
      height: "100%",
       
    }
  }
  },
  logotypeContainer: {
    backgroundColor: "#fff",
    width: "35%",
  //  height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", 
    [theme.breakpoints.only("sm")]: {
      width: "50%", 
    },
    
    [theme.breakpoints.down("xs")]: {
      order: "2",
      width: "100%",
    "&:before": {
      content: "''",
      backgroundImage: "url('"+ wave +"')",
       backgroundRepeat: "no-repeat",
       backgroundSize: "contain",
       position: "absolute",
      
       width: "100%",
       height: "100%",
       [theme.breakpoints.only("xs")]: {
        backgroundSize: "cover",
      },
    }
  },
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  formContainer: {
    width: "50%",
   // height: "100%",
    display: "flex",
    flexDirection: "column",
   // justifyContent: "center",
    alignItems: "center",
   // backgroundColor: "white",
    zIndex: 1,
    [theme.breakpoints.down("xs")]: {
      order: "1",
      height: "100%",
      width: "100%",
      
    },
    
  },
  form: {
    width: 320,
    textAlign: "-webkit-center",
    border: "1px solid #aeaa",
    padding: "50px 20px",
    boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
    borderRadius: "35px", 
  },
  regForm: {
    width: 450,
    textAlign: "-webkit-center",
    border: "1px solid #aeaa",
    padding: "20px",
    boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
    borderRadius: "35px", 
    
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light, 
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    
  },
  forgetButton: {
    
    fontWeight: 400,
    cursor: "pointer",
    "&:hover":{
      textDecoration: "underline"
    }
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },

  fullImg:{
    height: "75%"
  },

  title:{
    fontSize: 24,
    fontWeight: 600,
    //color: "#064be2",
    color:"#2196f3",
    marginBottom: 5,
    
  },
  subtitle:{
    marginBottom: 42,
    fontSize: "17px",
    fontWeight: 300,
  },
  center:{
    alignSelf: "center"
  },
  
  backdrop: {
    zIndex: 1500,
    color: '#fff',
  },
  large:{
    width: "80px",
    height: "80px", 
  },
  loginText:{
  // margin: "15px 0",
  color: "#6E6E6E",
  textTransform: "uppercase",
  fontSize: "2.1rem",
  fontWeight: 700
},
end:{
  display: "flex",
  justifyContent: "end",
  margin: "10px 50px" 
}
 
}));
