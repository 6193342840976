import { makeStyles } from "@material-ui/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
//import { fade } from "@material-ui/core/styles/colorManipulator";
import blue from '@material-ui/core/colors/blue';

export default makeStyles(theme => ({
  logotype: {
    color: "white",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },  
  appBar: {
    backgroundColor: "white  !important",
    width: `calc(100% - ${theme.spacing(7) + 14}px)`, 
    boxShadow: "0px 5px 10px 0px #2f3c4a14 !important",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
     
      width: "100%",
      
    },
    "& button":{
      backgroundColor: "#fff !important"
    },
    "& $MuiAppBar-positionFixed":{
      left: `${theme.spacing(7) + 14}px !important`,
    }
  },
  appBarOpen: {
    backgroundColor: "white  !important",
    boxShadow: "0px 5px 10px 0px #2f3c4a14 !important",
    width: `calc(100% - 187px)`, 
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& button":{
      backgroundColor: "#fff !important"
    },
    "& $MuiAppBar-positionFixed":{
      left: "187px !important",
    }
  },

  toolbar: {
    
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
   
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: 25,
    paddingLeft: theme.spacing(2.5),
    width: 36,
    backgroundColor: alpha(theme.palette.common.black, 0),
    transition: theme.transitions.create(["background-color", "width"]),
    "&:hover": {
      cursor: "pointer",
      backgroundColor: alpha(theme.palette.common.black, 0.08),
    },
  },
  searchFocused: {
    backgroundColor: alpha(theme.palette.common.black, 0.08),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 250,
    },
  },
  searchIcon: {
    width: 36,
    right: 0,
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: theme.transitions.create("right"),
    "&:hover": {
      cursor: "pointer",
    },
  },
  searchIconOpened: {
    right: theme.spacing(1.25),
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    height: 36,
    padding: 0,
    paddingRight: 36 + theme.spacing(1.25),
    width: "100%",
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
      // color: "white",
    },
  },
  headerMenuButton: {
  
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 28,
    color: "#2196f3", // new changes
  },
  headerIconCollapse: {
    color: "#2196f3", // new changes
    
  },
  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    cursor: "pointer",
    marginRight: theme.spacing(2),
    color: theme.palette.text.primary,
    '&:hover': {
      color: "#fff",
      backgroundColor: blue[500],  
    }
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  purchaseBtn: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    marginRight: theme.spacing(3)
  },

  drawer: {
    width: "50%",
    padding: "10px",
    [theme.breakpoints.only("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "60%",
    }
  },

  profile:{
   "& li":{
    padding: "0px 10px !important"
   }
  },

  dropdown:{
    outline: 0,
    position: "absolute", 
    overflowX: "hidden",
    overflowY: "auto", 
    right: "2%",
    top:"80%",
    minWidth: "16rem", 
    zIndex:"999",
    backgroundColor:"white",
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)", 
    [theme.breakpoints.down("sm")]: {
      minWidth: "15rem", 
      position:"fixed",
      top:'unset',
      right:'20px'
    },
  },
  
}));
